import { Components, Theme } from '@mui/material';

export const MuiInputLabel: Components<Theme>['MuiInputLabel'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      transition: theme.transitions.create(['color', 'opacity']),
      '&.Mui-focused, &.MuiFormLabel-filled': {
        transform: 'translate(12px, 8px) scale(0.7)',
      },
      '&.Mui-focused': {
        color: theme.palette.brand.main[100],
      },
      '&.Mui-disabled': {
        color: theme.palette.neutral01[500],
      },
      '&.Mui-error': {
        color: theme.palette.error.main,
      },
    }),
  },
};
