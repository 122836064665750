import { Buffer } from 'buffer';
import * as newProcess from 'process';

import { isProd } from 'utils/env.utils';

import EnvService from 'services/env';

// This only includes environment variables that are independent of the tenant. Those will be added later using EnvService.setTenantConfig
const config = new EnvService({
  DEFAULT_LOCALE: process.env.REACT_APP_DEFAULT_LOCALE,
  TENANT_CONFIG_URL: process.env.REACT_APP_TENANT_CONFIG_URL,
  DEV_LOCALE_BASE_URL: process.env.REACT_APP_DEV_LOCALE_BASE_URL,
  VERSION: process.env.REACT_APP_VERSION,
  BUILD_ENV: process.env.REACT_APP_BUILD_ENV,
  SPENCER_ENV: process.env.REACT_APP_SPENCER_ENV,
  ENABLE_DEV_TOOLS: process.env.REACT_APP_ENABLE_DEV_TOOLS === 'true' ? true : false,
  LOCAL_STORAGE_ID: process.env.REACT_APP_LOCAL_STORAGE_ID,
  REACT_QUERY_LOCAL_STORAGE_ID: process.env.REACT_APP_REACT_QUERY_LOCAL_STORAGE_ID,
  SENTRY: {
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_BUILD_ENV,
    release: isProd
      ? `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`
      : `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_BUILD_ENV}`,
  },
  NATIVE_SENTRY: {
    dsn: process.env.REACT_APP_NATIVE_SENTRY_DSN,
    environment: process.env.REACT_APP_BUILD_ENV,
    release: isProd
      ? `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`
      : `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_BUILD_ENV}`,
  },
  BUILD_HASH: process.env.BUILD_HASH,
});

export default config;

// shimming process and Buffer, needed to make @azure/event-hubs work. This adds less than 1kb to the bundle
Object.assign(self, {
  process: {
    ...newProcess,
    env: typeof process !== 'undefined' ? process.env : {}, // save the environment 🍃
  },
  global: self,
  Buffer,
});
