import { AxiosInstance } from 'axios';

import authApi from 'services/api/auth.api';
import configurationApi from 'services/api/configuration.api';
import workeloApi from 'services/api/workelo.api';

import chatApi from './chat.api';
import documentsApi from './documents.api';
import emergencyApi from './emergency.api';
import fileApi from './file.api';
import linksApi from './links.api';
import newsApi from './news.api';
import notificationsApi from './notifications.api';
import reactionsApi from './reactions.api';
import searchApi from './search.api';
import siteApi from './site.api';
import surveyApi from './survey.api';
import tokenApi from './token.api';
import topicApi from './topic.api';
import userApi from './user.api';

interface Options {
  api: AxiosInstance;
}

class RestApiService {
  public api: AxiosInstance;

  constructor(options: Options) {
    this.api = options.api;
  }

  // AUTH
  public getConfig = authApi.getConfig;

  // CONFIGURATION
  public getMe = configurationApi.getMe;
  public getEnabledModules = configurationApi.getEnabledModules;

  // USER
  public getUsers = userApi.getUsers;
  public getUser = userApi.getUser;
  public putUser = userApi.putUser;
  public postUserFeedback = userApi.postUserFeedback;
  public postUserProfilePicture = userApi.postUserProfilePicture;
  public getUserProfilePicture = userApi.getUserProfilePicture;
  public getUserColleagues = userApi.getUserColleagues;
  public getUserTeam = userApi.getUserTeam;
  public createBookmark = userApi.createBookmark;
  public deleteBookmark = userApi.deleteBookmark;
  public checkBookmarked = userApi.checkBookmarked;
  public getBookmarks = userApi.getBookmarks;

  // SITE
  public getSites = siteApi.getSites;
  public getSite = siteApi.getSite;
  public getZones = siteApi.getZones;

  // NOTIFICATIONS
  public getSubscriptions = notificationsApi.getSubscriptions;
  public putSubscriptions = notificationsApi.putSubscriptions;
  public getApplication = notificationsApi.getApplication;
  public createApplicationEndpoint = notificationsApi.createApplicationEndpoint;
  public putApplication = notificationsApi.putApplication;
  public getNotificationTopics = notificationsApi.getNotificationTopics;

  // TOKEN
  public getFeedToken = tokenApi.getFeedToken;
  public getMeasurementsToken = tokenApi.getMeasurementsToken;

  // EMERGENCY
  public getEmergencies = emergencyApi.getEmergencies;

  // NEWS
  public postFeedback = newsApi.postFeedback;

  // TOPIC
  public getNewsTopics = topicApi.getNewsTopics;
  public getUserTopics = topicApi.getUserTopics;
  public deleteUserTopic = topicApi.deleteUserTopic;
  public createUserTopic = topicApi.createUserTopic;

  // LINKS
  public getLinks = linksApi.getLinks;
  public getLinkById = linksApi.getLinkById;
  public postFeedbackLinkById = linksApi.postFeedbackLinkById;

  // SEARCH
  public getSearch = searchApi.search;

  // SURVEY
  public postSurvey = surveyApi.postSurvey;
  public getSurvey = surveyApi.getSurvey;
  public getSurveyAnswers = surveyApi.getSurveyAnswers;
  public getPollAnswers = surveyApi.getPollAnswers;
  public updateSurveyAnswers = surveyApi.updateSurveyAnswers;
  public postForm = surveyApi.postForm;

  // FILE / MEDIA
  public uploadMedia = fileApi.uploadMedia;
  public getMediaById = fileApi.getMediaById;

  // DOCUMENTS
  public getDocument = documentsApi.getDocument;
  public getDocumentLibrary = documentsApi.getDocumentLibrary;
  public getDocumentLibraryFolder = documentsApi.getDocumentLibraryFolder;
  public getDocumentLibraryFavourites = documentsApi.getDocumentLibraryFavourites;
  public getDocumentLibraryFavouriteIds = documentsApi.getDocumentLibraryFavouriteIds;
  public createDocumentLibraryFavourite = documentsApi.createDocumentLibraryFavourite;
  public deleteDocumentLibraryFavourite = documentsApi.deleteDocumentLibraryFavourite;

  // REACTIONS
  public deleteStreamReaction = reactionsApi.deleteStreamReaction;

  // CHAT
  public getChatUserToken = chatApi.getChatUserToken;

  // Workelo Magic Link
  public getWorkeloMagicLink = workeloApi.getWorkeloMagicLink;
}

export default RestApiService;
