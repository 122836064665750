import { Box } from '@mui/material';
import React, { lazy, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import {
  matchPath,
  matchRoutes,
  Outlet,
  ScrollRestoration,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { isNative } from 'utils/capacitor.utils';

import { Routes } from 'constants/routes.constants';
import { useMedia } from 'hooks/useMedia';
import { useCurrentUser } from 'queries';
import { useDeviceInfo } from 'services/capacitor/CapacitorProvider';
import { useMeasurements } from 'services/measurements';
import { MeasurementEntity, MeasurementEventName } from 'types/measurement.types';

import { useAllRoutes } from 'pages/routes';

import { DefaultHeader, Footer, NavbarContext } from 'components/@navbar';
import { DrawerNavigation } from 'components/@navigations';
import { Onboarding } from 'components/@onboarding';

import ZoomScaleContainer from './ZoomScaleContainer';

// We lazy load this chunk because it's only needed for native platforms (reduces bundle size for web)
const NotificationProvider = lazy(() => import('services/push-notifications/NotificationProvider'));

interface Props {
  children?: ReactNode;
}

const Layout = ({ children }: Props) => {
  const { isDesktop } = useMedia();
  const navigate = useNavigate();
  const location = useLocation();
  const sendEvent = useMeasurements();
  const currentModuleId = useRef<string>();
  const allRoutes = useAllRoutes();
  const { user } = useCurrentUser();

  const { bottom } = useDeviceInfo();

  useEffect(() => {
    // Navigate to /news by default
    if (matchPath(location.pathname, '/')) {
      navigate(Routes.News, { replace: true });
    }
  }, [navigate, location.pathname]);

  const matchedRoutes = matchRoutes([allRoutes], location.pathname);
  const exactMatch = matchedRoutes?.find((route) => matchPath(route.pathname, location.pathname));
  const moduleId = exactMatch?.route?.moduleId;
  useEffect(() => {
    // page visit event
    const lastModuleId = currentModuleId.current;
    currentModuleId.current = moduleId;

    if (!moduleId || lastModuleId === moduleId) return;

    sendEvent({
      entity: MeasurementEntity.Module,
      eventName: MeasurementEventName.Show,
      data: { moduleId },
    });
  }, [moduleId, sendEvent]);

  const hideLayout = exactMatch?.route?.hideLayout || !user?.webOnboarded;

  const mouseUpListener = () => {
    if (!currentModuleId.current) return;

    sendEvent({
      entity: MeasurementEntity.Module,
      eventName: MeasurementEventName.Interaction,
      data: { moduleId: currentModuleId.current },
    });
  };

  const [header, setHeader] = useState<React.ReactNode | null>(<DefaultHeader />);
  const [footer, setFooter] = useState<React.ReactNode | null>(<Footer />);

  const contextValue = useMemo(() => ({ header, setHeader, footer, setFooter }), [header, footer]);

  if (!user?.webOnboarded || (isNative && !user?.mobileOnboarded)) return <Onboarding />;

  const Wrapper = isNative ? NotificationProvider : React.Fragment;

  return (
    <Wrapper>
      <NavbarContext.Provider value={contextValue}>
        <ScrollRestoration
          getKey={(location) => {
            // restore scroll position when navigating to /news or /search
            if (location.pathname === Routes.News) return location.pathname;
            if (location.pathname === Routes.Search) return location.pathname;

            return location.key;
          }}
        />
        <Box minHeight="100svh" display="flex" flexDirection="column" onMouseUp={mouseUpListener}>
          {!hideLayout && header}
          <Box
            display="flex"
            flexDirection="row"
            flex="1 auto"
            sx={(theme) => ({
              height: isDesktop ? `calc(100vh - ${theme.mixins.header.height})` : '100%',
              paddingBottom: `${bottom}px`,
            })}
          >
            {!hideLayout && isDesktop && <DrawerNavigation />}

            <ZoomScaleContainer>{children ? children : <Outlet />}</ZoomScaleContainer>
          </Box>

          {!hideLayout && !isDesktop && footer}
        </Box>
      </NavbarContext.Provider>
    </Wrapper>
  );
};

export default Layout;
