/* 
READ BEFORE GOING THROUGH THIS FILE:
  Why we built it like this: creating a gooey text effect with a gaussian blur that looks nice and works across all common browsers (tested on Safari, Firefox & Chrome)
  is incredibly difficult (trust us, it really really is). We tried a lot of different approaches, but the only one that worked was to split the text into lines and
  render each line separately. The code in this file takes a rendered DOMnode and calculates which text is on each line.
  
  This is a somewhat strange approach but it works and is quite performant 🤷‍♂️
*/

// --------------------------------------------------------------------------- //
// --------------------------------------------------------------------------- //
// --------------------------------------------------------------------------- //
// --------------------------------------------------------------------------- //

// source: https://www.bennadel.com/blog/4310-detecting-rendered-line-breaks-in-a-text-node-in-javascript.htm

/**
 * I extract the visually rendered lines of text from the given textNode as it
 * exists in the document at this very moment. Meaning, it returns the lines of
 * text as seen by the user.
 */
export const extractLinesFromTextNode = ({
  textNode,
  firstRowMinWidth = 0,
  isMobile,
}: {
  textNode: ChildNode;
  firstRowMinWidth: number;
  isMobile: boolean;
}) => {
  if (textNode.nodeType !== 3) {
    throw new Error('Lines can only be extracted from text nodes.');
  }

  // BECAUSE SAFARI: None of the "modern" browsers seem to care about the actual
  // layout of the underlying markup. However, Safari seems to create range
  // rectangles based on the physical structure of the markup (even when it
  // makes no difference in the rendering of the text). As such, let's rewrite
  // the text content of the node to REMOVE SUPERFLUOS WHITE-SPACE. This will
  // allow Safari's .getClientRects() to work like the other modern browsers.
  textNode.textContent = collapseWhiteSpace(textNode.textContent || '');

  // A Range represents a fragment of the document which contains nodes and
  // parts of text nodes. One thing that's really cool about a Range is that we
  // can access the bounding boxes that contain the contents of the Range. By
  // incrementally adding characters - from our text node - into the range, and
  // then looking at the Range's client rectangles, we can determine which
  // characters belong in which rendered line.
  const textContent = textNode.textContent || '';
  const range = document.createRange();
  let lines = [];
  let lineCharacters: Array<string> = [];

  // Iterate over every character in the text node.
  for (let i = 0; i < textContent.length; i++) {
    // Set the range to span from the beginning of the text node up to and
    // including the current character (offset).
    range.setStart(textNode, 0);
    range.setEnd(textNode, i + 1);

    // At this point, the Range's client rectangles will include a rectangle
    // for each visually-rendered line of text. Which means, the last
    // character in our Range (the current character in our for-loop) will be
    // the last character in the last line of text (in our Range). As such, we
    // can use the current rectangle count to determine the line of text.
    const lineIndex = range.getClientRects().length - 1;

    // If this is the first character in this line, create a new buffer for
    // this line.
    if (!lines[lineIndex]) {
      lineCharacters = [];
      lines.push(lineCharacters);
    }

    // Add this character to the currently pending line of text.
    lineCharacters.push(textContent.charAt(i));
  }

  // At this point, we have an array (lines) of arrays (characters). Let's
  // collapse the character buffers down into a single text value.
  lines = lines.map((characters) => {
    return collapseWhiteSpace(characters.join(''));
  });

  // ---- This was added by N5 ----
  // Get the width of each line, so we can add/remove some margin-right to make sure
  // lines are 30px longer than the previous line. This is needed to get a nice gooey effect.
  const lineWidths = Array.from(range.getClientRects()).map((rect) => rect.width);

  const finalLineWidths: Array<number> = [];

  lineWidths?.forEach((lineWidth, i) => {
    if (i === 0) {
      return (finalLineWidths[i] = Math.max(lineWidth, firstRowMinWidth));
    }

    const previousLineWidth = finalLineWidths[i - 1];
    const difference = previousLineWidth - lineWidth;

    let finalWidth = lineWidth;

    // if prev is only slightly longer, we set the width to the same as prev
    if (difference < 0 && difference > (isMobile ? -22 : -25)) {
      finalWidth = previousLineWidth;
    }

    // if prev is only slightly shorter, we set the width to the same as prev
    if (difference > 0 && difference < 30) {
      finalWidth = previousLineWidth;
    }
    // othwerise, difference is large enough to not have to do anything

    finalLineWidths[i] = finalWidth;
  });

  const marginRights = finalLineWidths.map((width, i) => width - lineWidths[i]);

  return lines.map((line, index) => ({
    text: line,
    marginRight: marginRights[index],
    totalTextWidth: finalLineWidths[index],
  }));
};

/**
 * I normalize the white-space in the given value such that the amount of white-
 * space matches the rendered white-space (browsers collapse strings of white-space
 * down to single space character, visually, and this is just updating the text to
 * match that behavior).
 */
const collapseWhiteSpace = (value: string) => {
  return value.trim().replace(/\s+/g, ' ');
};
