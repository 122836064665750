import { AppConfigurationResponse, MeResponse } from 'types/configuration.types';

import RestApiService from '.';

export default {
  getMe(this: RestApiService) {
    return this.api.get<MeResponse>(`/api/me`);
  },
  getEnabledModules(this: RestApiService) {
    return this.api.get<AppConfigurationResponse>(`/api/configuration`);
  },
};
