import { createRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import { Modules } from 'components/@navigations/config';

export default createRoute({
  path: Routes.SurveyDetail,
  lazy: () => import('./SurveyDetail'),
  hideLayout: true,
  moduleId: Modules.Surveys,
});
