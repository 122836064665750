import { createRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import Organisation from './Organisation';
import people from './people';
import sites from './sites';

export default createRoute({
  path: Routes.Organisation,
  Component: Organisation,
  children: [people, sites],
});
