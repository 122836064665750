import { Box, Typography, TypographyProps } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { useMedia } from 'hooks/useMedia';
import { useTenantConfig } from 'queries';

import { extractLinesFromTextNode } from './utils';

type Props = TypographyProps & {
  withTopics?: boolean;
  firstRowMinWidth?: number;
  backgroundColor?: string;
};

/*
Oh boy.
The design for this component was pretty difficult to get right, even more so when looking at cross-browser support.
It even had some slight bugs in Figma like overlapping text, which we ran into as well, and had to fix creatively.

What's happening here:
1. We render the text invisibly and use extractLinesFromTextNode to get the text by line and each line's width
2. Using the calculated widths, the backgrounds are rendered without text to prevent overlapping on long characters (see other comment further down)
3. We add the sloping corners using pseudo elements, depending on whether the previous line is longer or shorter than the current line
4. The text is rendered over top
*/

const GooeyTypography = ({
  withTopics,
  children,
  firstRowMinWidth = 0,
  backgroundColor = 'brand.main.100',
  ...rest
}: Props) => {
  const { isMobile, isDesktop } = useMedia();
  const textRef = useRef<HTMLSpanElement | null>(null);
  const [textByLine, setTextByLine] = useState<
    Array<{ text: string; marginRight: number; totalTextWidth: number }>
  >([]);
  const lineRefs = useRef<Array<HTMLDivElement>>([]);
  const zoomFactor = useTenantConfig()?.data?.configuration?.zoom;
  const enableZoom = zoomFactor && isDesktop;

  useEffect(() => {
    // the styling of this component is based on the text width and wrapping, so we need to calculate it on mount + on resize
    if (!textRef.current) return;
    const resizeObserver = new ResizeObserver((entries) => {
      const element = entries[0]?.target?.firstChild;
      if (!element) return;

      const lengths = extractLinesFromTextNode({ textNode: element, firstRowMinWidth, isMobile });
      setTextByLine(lengths);
    });

    resizeObserver.observe(textRef.current);
    return () => resizeObserver.disconnect();
  }, [firstRowMinWidth, isMobile, children]);

  // Needed for the row layout, i.e. border radiuses and pseudo elements
  const getRowProperties = (index: number) => {
    const isFirst = index === 0;
    const isLast = index === textByLine.length - 1;

    return {
      isFirst,
      isLast,
      isSameAsPrevious: isFirst
        ? false
        : textByLine[index].totalTextWidth === textByLine[index - 1].totalTextWidth,
      isSameAsNext: isLast
        ? false
        : textByLine[index].totalTextWidth === textByLine[index + 1].totalTextWidth,
      isLongerThanNext: isLast
        ? false
        : textByLine[index].totalTextWidth > textByLine[index + 1].totalTextWidth,
      isShorterThanPrevious: isFirst
        ? false
        : textByLine[index].totalTextWidth < textByLine[index - 1].totalTextWidth,
    };
  };

  const spacingValueL = isMobile ? 2 : 3;
  const spacingValueR = 3;
  const spacingValueY = isMobile ? 1 : 3;

  return (
    <div>
      {textByLine.map(({ text, marginRight, totalTextWidth }, index) => {
        const {
          isFirst,
          isLast,
          isSameAsPrevious,
          isSameAsNext,
          isLongerThanNext,
          isShorterThanPrevious,
        } = getRowProperties(index);

        const spacingValues = {
          pl: spacingValueL,
          pr: spacingValueR,
          mt: isFirst ? 0 : -spacingValueY,
          // less bottom padding on last line on desktop
          pt: isMobile
            ? spacingValueY
            : index === textByLine.length - 1
              ? spacingValueY - 1
              : spacingValueY,
          pb: isMobile
            ? spacingValueY
            : index === textByLine.length - 1
              ? spacingValueY - 1
              : spacingValueY,
        };

        return (
          <Box key={index} position="relative">
            <Box
              className="gooey-text"
              ref={(ref) => (lineRefs.current[index] = ref as HTMLDivElement)}
              sx={(theme) => ({
                backgroundColor,
                display: 'inline-block',
                position: 'relative',
                transition: theme.transitions.create(['background-color']),
                maxWidth:
                  totalTextWidth / (enableZoom ? Number(zoomFactor) : 1) +
                  Number(theme.spacing(spacingValueL + spacingValueR).replace('px', '')),
                width: '100%',
                ...spacingValues,

                borderTopLeftRadius: withTopics ? 0 : isFirst ? 16 : 0,
                borderTopRightRadius:
                  isFirst || (!isShorterThanPrevious && !isSameAsPrevious) ? 16 : 0,
                borderBottomRightRadius: isLast || isLongerThanNext ? 16 : 0,
                borderBottomLeftRadius: isLast ? 16 : 0,

                // to let the element flow into the next line
                ':before':
                  !isLast && !isLongerThanNext && !isSameAsNext
                    ? {
                        content: '""',
                        backgroundColor,
                        transition: theme.transitions.create(['background-color']),
                        zIndex: 4,
                        width: 16,
                        height: 16,
                        overflow: 'hidden',
                        position: 'absolute',
                        right: -15,
                        bottom: isMobile ? 7 : 23.5,
                        mask: 'radial-gradient(circle at 16px 0px, transparent 16px, #000 16px)',
                      }
                    : undefined,

                // to let the element flow into the previous line
                ':after':
                  !isFirst && isShorterThanPrevious
                    ? {
                        content: '""',
                        backgroundColor,
                        transition: theme.transitions.create(['background-color']),
                        zIndex: 4,
                        width: 16,
                        height: 16,
                        overflow: 'hidden',
                        position: 'absolute',
                        right: -15,
                        top: isMobile ? 7 : 23.5,
                        mask: 'radial-gradient(circle at 16px 16px, transparent 16px, #000 16px)',
                      }
                    : undefined,
              })}
            >
              {/*
                Because of the design, the bottom of long characters like p & g is cut off;
                the same happens in the Figma design, because the background can't live separately from the text.
                That's why we're only rendering a space character here, while the actual text is rendered separately.
              */}
              <Typography marginRight={`${marginRight}px`} {...rest}>
                &nbsp;
              </Typography>
            </Box>

            {/* Actual text is rendered here (see above comment) */}
            <Box
              position="absolute"
              zIndex={1}
              top={0}
              sx={{ ...spacingValues, pointerEvents: 'all' }}
            >
              <Typography color="brand.main.invert" {...rest}>
                {text}
              </Typography>
            </Box>
          </Box>
        );
      })}

      {/* Only used to calculate which text is on which line. This is never displayed to the user. */}
      <Typography
        {...rest}
        px={3}
        py={0}
        height={0}
        overflow="hidden"
        sx={{ wordBreak: 'break-word' }}
      >
        <Box ref={textRef}>{children}</Box>
      </Typography>
    </div>
  );
};

export default GooeyTypography;
