import { useQueries, UseQueryOptions } from '@tanstack/react-query';

import { QueryKeys } from 'queries';
import { Duration } from 'queries/constants';
import { apiService } from 'services';
import { useSelectedLocale } from 'services/i18n';
import { Href } from 'types/api.types';
import { Topic } from 'types/topics.types';

const fetchTopics = async (href: string) => {
  const { data: response } = await apiService.api.get<Topic>(href);
  return { ...response, href };
};

type TopicQueryOptions = Omit<
  UseQueryOptions<Topic, Error, Topic, ReturnType<typeof QueryKeys.topics.byId>>,
  'queryKey' | 'queryFn'
>;

export const useTopics = (activities: Array<Href>, config: TopicQueryOptions = {}) => {
  const { locale } = useSelectedLocale();
  const results = useQueries({
    queries: activities.map((activity) => ({
      ...config,
      queryKey: QueryKeys.topics.byId(activity.href, locale),
      queryFn: () => fetchTopics(activity.href),
      staleTime: Duration.FIVE_MIN,
      enabled: !!activity.href,
    })),
    combine: (queries) => {
      const topics = queries
        .reduce<Array<Topic>>((acc, query) => {
          if (query.data) acc.push(query.data);
          return acc;
        }, [])
        .sort((a, b) => a.position - b.position);
      const isLoading = !!activities.length && queries.some((item) => item.isLoading);

      return {
        topics,
        isLoading,
      };
    },
  });

  return {
    topics: results.topics,
    isLoading: results.isLoading,
  };
};
