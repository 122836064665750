import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { QueryKeys } from 'queries';
import { Duration } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { ChatUserToken } from 'types/chat.types';

const fetchChatUserToken = async () => {
  const { data: response } = await apiService.getChatUserToken();
  return response;
};

export const useChatUserToken = (
  config: UseCommonQueryOptions<
    ChatUserToken,
    AxiosError,
    ChatUserToken,
    ReturnType<typeof QueryKeys.token.chat>
  > = {},
) => {
  const { data, status, isLoading, error, refetch } = useQuery({
    queryKey: QueryKeys.token.chat(),
    queryFn: () => fetchChatUserToken(),
    refetchInterval: Duration.ONE_HOUR,
    gcTime: Duration.ONE_HOUR,
    enabled: config.enabled ?? true,
    ...config,
  });

  return {
    data,
    status,
    isLoading,
    error,
    refetch,
  };
};
