import { Link } from 'react-router-dom';

import { Href, Video } from './api.types';

export enum ActivityType {
  News = 'news',
  Survey = 'survey',
}

export enum CardType {
  Regular = 'regular',
  Highlighted = 'highlighted',
  Spotlighted = 'spotlighted',
  Emergency = 'emergency',
}

type Reaction = {
  id: string;
  kind: string;
};

export type ReactionCount = {
  [key: string]: number;
};

export enum ReactionKind {
  Like = 'like',
  Love = 'love',
  Inspired = 'inspired',
  Celebrate = 'celebrate',
  Curious = 'curious',
  Comment = 'comment',
}

export type ActivityResult = {
  id: string;
  actor: string;
  href: string;
  foreignId: string;
  highlight: boolean;
  activity_type: ActivityType;
  languages: Array<string>;
  featured: boolean;
  topics: Array<number>;
  priority_publication: boolean;
  reaction_counts: ReactionCount;
  own_reactions: {
    [key: string]: Array<Reaction>;
  };
};

export type NewsItemTypeWithActivity = NewsItemType & {
  streamActivity?: ActivityResult;
};

export type NewsItemType = {
  id: number;
  uuid: string;
  activityId?: string;
  attachments: Array<Href>;
  commentFeature: boolean;
  author: string;
  highlight: boolean;
  featured: boolean;
  publishedAt: string;
  title: string;
  remoteUrl: string;
  featureVideo?: Video;
  featureMedia?: Href;
  featureMediaSquare?: Href;
  survey?: Href;
  description: string;
  content: string;
  summary: string;
  topics: Array<Href>;
  news: Array<Href>;
  isLikeable: boolean;
};

export type NewsProps = {
  limit: number;
  page: string;
};

export type Attachment = {
  extension: string;
  fileSize: number;
  id: string;
  mimeType: string;
  name: string;
  signedUrl: string;
};

export type NewsFeedbackParams = {
  feedback: string;
};

export type LinkNewsItemParams = {
  newsItem?: NewsItemType;
  activityType?: ActivityType;
  cardType?: CardType;
  toComments?: boolean;
};

export type LinkNewsItem =
  | {
      component: typeof Link;
      to: string;
      target?: string;
      state?: {
        from: string;
        cardType?: CardType;
      };
    }
  | undefined;
