import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { expectedError } from 'utils/browser.utils';

import { QueryKeys } from 'queries';
import { Duration } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { Href } from 'types/api.types';
import { UserProfilePictureGetParams } from 'types/user.types';

import { convertToBase64 } from 'components/@profile/ProfilePicture/utils';

interface Params {
  picture?: Href;
  params?: UserProfilePictureGetParams;
}

export const fetchPicture = async (href: string, params?: UserProfilePictureGetParams) => {
  try {
    const { data: response } = await apiService.api(href, { responseType: 'blob', params });
    const base64 = await convertToBase64(response);
    return base64;
  } catch (error) {
    if (error instanceof AxiosError && expectedError(error)) return '';
    throw error;
  }
};

export const usePicture = (
  { picture, params }: Params,
  config: UseCommonQueryOptions<string, ReturnType<typeof QueryKeys.images.imageUrl>> = {},
) => {
  const { isSuccess, data, status, isLoading, error } = useQuery({
    queryKey: QueryKeys.images.imageUrl(picture?.href, params),
    queryFn: () => fetchPicture(picture?.href ?? '', params),
    staleTime: Duration.TEN_MIN,
    enabled: !!picture?.href,
    ...config,
  });

  return {
    isSuccess,
    data: error ? undefined : data,
    status,
    isLoading,
    error,
  };
};
