import { flush } from '@sentry/react';
import { AxiosError } from 'axios';
import { jwtDecode } from 'jwt-decode';
import { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import shajs from 'sha.js';

import config from 'config';

import { isNative } from 'utils/capacitor.utils';
import { captureException, setExtra, setTag, setUser } from 'utils/sentry.utils';

import {
  useCurrentUser,
  useEnabledModules,
  useInitialiseLocales,
  useLogOut,
  useMe,
  useTenantConfig,
} from 'queries';
import { useChatUserToken } from 'queries/chat';
import useChatModuleEnabled from 'services/chat/hooks/useChatModuleEnabled';
import { useSelectedLocale } from 'services/i18n';
import { getAccessToken } from 'services/identity';
import { parseJwt } from 'services/prefetcher/util';
import { useNotifications } from 'services/snackbar';
import { intlActions } from 'store/intl/intl.slice';
import { userActions } from 'store/user/user.slice';
import { IdToken } from 'types/token.types';

import { Splash } from 'components/@splash';

import { usePrefetchNonBlocking } from './hook';

interface Props {
  children: ReactNode;
}

// Prefetch API calls that are needed before rendering the rest of the app
const PrefetchGate = ({ children }: Props) => {
  const dispatch = useDispatch();
  const { locale } = useSelectedLocale();
  const { logOut } = useLogOut();
  const { error } = useNotifications();

  const { isChatEnabled } = useChatModuleEnabled();

  // Prefetch calls that are blocking
  const { isLoading: isLoadingIntlMessages, intl, isError: intlError } = useInitialiseLocales();
  const { isLoading: isLoadingEnabledModules, error: errorModules } = useEnabledModules();
  const { isLoading: isLoadingMe, error: errorMe } = useMe();
  const { isLoading: isLoadingUser, user, userId, error: errorUser } = useCurrentUser();
  // INFO: Fetch chat token only if at least one of the chat module is enabled
  const { isLoading: isLoadingChatUserToken, error: chatUserTokenError } = useChatUserToken({
    enabled: isChatEnabled,
  });
  const tenant = useTenantConfig();

  // Prefetch calls that are non-blocking
  usePrefetchNonBlocking();

  const isLoading =
    !userId ||
    isLoadingEnabledModules ||
    isLoadingMe ||
    isLoadingUser ||
    isLoadingIntlMessages ||
    (isLoadingChatUserToken && isChatEnabled);

  // Retrieve userId from our accessToken
  useEffect(() => {
    const setUserWithAccessToken = async () => {
      const token = await getAccessToken();
      if (token) {
        dispatch(userActions.updateUser(jwtDecode<IdToken>(token).spencer_id));
      }
    };
    setUserWithAccessToken();
  }, [dispatch]);

  // Set Sentry user so we can track errors per user
  useEffect(() => {
    if (!userId) return;
    const encryptedUserId = new shajs.sha256().update(userId).digest('hex');
    setUser({ id: encryptedUserId });
  }, [userId]);

  // Set Sentry user so we can track errors per user
  useEffect(() => {
    if (!tenant.data?.id) return;
    setTag('customer', tenant.data?.id);
  }, [tenant]);

  // Set Sentry locale so we know which locale the user is using
  useEffect(() => {
    setExtra('selectedLocale', locale);
  }, [locale]);

  // Set locale based on the user's webLanguage
  useEffect(() => {
    // If we are loading the user or dont have a userId yet, dont run this
    if (isLoadingUser || !userId) return;
    // If the user does not have a webLanguage set, use the default locale
    // Downside of this is, that the user will not be able to persist his language preferences between sessions
    // But if we don't do this, the user will see translation keys instead of the actual translations in some cases that the default language for the customer is not English
    if (!user?.webLanguage) {
      dispatch(intlActions.updateLocale(config.LOCALES.default));
    } else {
      const localeIsValid = config.LOCALES.available.includes(user.webLanguage);
      const newLocale = localeIsValid ? user.webLanguage : config.LOCALES.default;
      dispatch(intlActions.updateLocale(newLocale));
    }
  }, [dispatch, isLoadingUser, userId, user?.webLanguage]);

  useEffect(() => {
    const checkErrors = async () => {
      if (!!errorModules || !!errorMe || !!errorUser) {
        const hasNetworkError =
          !!(errorModules?.code === AxiosError.ERR_NETWORK) ||
          !!(errorMe?.code === AxiosError.ERR_NETWORK) ||
          !!(errorUser?.code === AxiosError.ERR_NETWORK) ||
          !!(chatUserTokenError?.code === AxiosError.ERR_NETWORK);

        if (hasNetworkError) {
          // if the error is caused by bad/absent internet, we don't logout the user but instead show a toast
          // we try to localize the message, but if intl wasn't loaded yet, we just show an English message
          const title =
            (intl?.[locale]?.messages?.core_general_alert_error_title_connection as string) ||
            'Network error';
          const message =
            (intl?.[locale]?.messages?.core_general_alert_error_body_connection as string) ||
            'Please check your internet connection and refresh the page';

          error({ title, message });
          return;
        }

        // token refresh happens automatically when the user has a valid refresh token
        const encodedToken = (await getAccessToken()) || '';
        const token = parseJwt(encodedToken);
        const needsTokenRefresh = !!token?.exp && token.exp * 1000 < Date.now();
        if (needsTokenRefresh) return;

        if (errorModules) captureException(errorModules);
        if (errorMe) captureException(errorMe);
        if (errorUser) captureException(errorUser);

        //Flush does not exist yet in @sentry/capacitor
        if (!isNative) await flush(2000);

        logOut();
      }
    };

    checkErrors();
  }, [errorModules, chatUserTokenError, errorMe, errorUser, intl, locale, logOut, error]);

  if (intlError) {
    throw new Error('Something went wrong while loading the translations');
  }

  return <Splash isLoading={isLoading}>{children}</Splash>;
};

export default PrefetchGate;
