export enum NotificationTypes {
  Info = 'Info',
  Success = 'Success',
  Warning = 'Warning',
  Error = 'Error',
}

export type NotificationPayload = {
  title?: string;
  message?: string;
  type: NotificationTypes;
};
