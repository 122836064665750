import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { IconNames } from 'sprite/types';

import { useMedia } from 'hooks/useMedia';
import { NotificationPayload, NotificationTypes } from 'types/snackbar.types';

import Icon from 'components/@icons';

const iconLookup: Record<NotificationTypes, IconNames> = {
  [NotificationTypes.Warning]: 'IcoWarning',
  [NotificationTypes.Error]: 'IcoError',
  [NotificationTypes.Success]: 'IcoCheck',
  [NotificationTypes.Info]: 'IcoWarning',
};

type Props = NotificationPayload & {
  notificationKey: string;
};

const Notification = ({ title, message, type, notificationKey }: Props) => {
  const { isMobile } = useMedia();
  const theme = useTheme();
  const { closeSnackbar } = useSnackbar();

  const icon = iconLookup[type];

  const colorLookup = {
    [NotificationTypes.Success]: theme.palette.success.main,
    [NotificationTypes.Warning]: theme.palette.warning.main,
    [NotificationTypes.Error]: theme.palette.error.main,
    [NotificationTypes.Info]: theme.palette.brand.main[100],
  };
  const color = colorLookup[type];

  return (
    <Box
      bgcolor={color}
      width={isMobile ? 'calc(100vw - 16px)' : '100%'}
      height="100%"
      display="flex"
      padding={2}
      pr={1}
      sx={{ wordBreak: 'break-word' }}
      alignItems="center"
      borderRadius={4}
      minWidth={320}
      gap={2}
    >
      {icon && (
        <Box display="flex">
          <Icon name={icon} fill={theme.palette.neutral01[700]} fontSize={40} />
        </Box>
      )}
      <Box display="flex" flexDirection="column">
        {title && (
          <Typography variant="overline" fontWeight="bold">
            {title}
          </Typography>
        )}
        {message && (
          <Typography variant="overline" color="neutral01.100" margin="none">
            {message}
          </Typography>
        )}
      </Box>

      <IconButton
        sx={{ marginLeft: 'auto' }}
        onClick={() => closeSnackbar(notificationKey)}
        aria-label="close"
      >
        <Icon name="IcoClose" color={theme.palette.neutral01[100]} />
      </IconButton>
    </Box>
  );
};

export default Notification;
