import { Alert, Collapse, IconButton, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { useTenantConfig } from 'queries';
import { getShowBanner } from 'store/cookie-banner/cookie-banner.selectors';
import { cookieBannerActions } from 'store/cookie-banner/cookie-banner.slice';

import Icon from 'components/@icons';

const CookieBanner = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const showBanner = useSelector(getShowBanner);
  const { data: tenant } = useTenantConfig();

  const handleShowBanner = () => {
    dispatch(cookieBannerActions.toggleCookieBanner(false));
  };

  return (
    <>
      {showBanner && (
        <Collapse in={showBanner}>
          <Alert
            action={
              <IconButton
                color="inherit"
                size="small"
                onClick={handleShowBanner}
                aria-label={intl.formatMessage({ id: 'core_general_close' })}
              >
                <Icon name="IcoClose" fontSize={24} color="brand.action.contrast.invert" />
              </IconButton>
            }
            icon={false}
            severity="info"
            variant="standard"
          >
            <Typography variant="body2" color="brand.action.contrast.invert" mb={0.5}>
              <FormattedMessage id="core_cookies_banner_title" />
            </Typography>

            <Typography
              variant="overline"
              color="brand.action.contrast.invert"
              sx={(theme) => ({
                '& a': {
                  textDecoration: 'underline',
                  color: theme.palette.brand.action.contrast.invert,
                },
              })}
            >
              <FormattedMessage
                id="core_cookies_banner_description"
                values={{
                  a: (chunks) => (
                    <a
                      target="_blank"
                      href={tenant?.configuration?.links?.cookie ?? ''}
                      rel="noreferrer"
                    >
                      {chunks}
                    </a>
                  ),
                }}
              />
            </Typography>
          </Alert>
        </Collapse>
      )}
    </>
  );
};

export default CookieBanner;
