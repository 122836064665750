import { Components, Theme } from '@mui/material';

export const MuiTablePagination: Components<Theme>['MuiTablePagination'] = {
  styleOverrides: {
    menuItem: ({ theme }) => ({
      ':hover': {
        backgroundColor: theme.palette.brand.main[10],
        color: theme.palette.brand.main[100],
      },
    }),
  },
};
