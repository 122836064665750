import { Box, SxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { IconNames } from 'sprite/types';

import Icon from 'components/@icons';

interface Props {
  title: ReactNode;
  description?: ReactNode;
  icon?: IconNames;
  action?: ReactNode;
  noMargin?: boolean;
  bgcolor?: string;
}

const EmptyState = ({
  icon = 'IcoNews',
  title,
  description,
  action,
  noMargin,
  bgcolor,
  ...rest
}: Props & SxProps) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      py={10}
      px={2}
      m={noMargin ? 0 : 2}
      bgcolor={bgcolor || 'neutral01.300'}
      sx={{ borderRadius: 4, ...rest }}
    >
      <Icon
        name={icon}
        fontSize={40}
        color={icon === 'IcoWarning' ? 'error.main' : 'brand.main.100'}
      />

      <Typography variant="h5" textAlign="center" mb={1}>
        {title}
      </Typography>
      {description && (
        <Typography variant="body2" fontWeight="bold" textAlign="center">
          {description}
        </Typography>
      )}
      {!!action && <Box mt={2}>{action}</Box>}
    </Box>
  );
};

export default EmptyState;
