import { Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { isNative } from 'utils/capacitor.utils';

import { useCurrentUser, useUserUpdate } from 'queries';

import CookieBanner from 'components/@onboarding/CookieBanner';
import OnboardingStep from 'components/@onboarding/OnboardingStep';
import { OnboardingProps, OnboardingSteps } from 'components/@onboarding/types';
import { NewsSubscriptionsForm } from 'components/@settings';

const SelectTopics = ({ setActiveStep, skipPrivacyStep, skipLanguageStep }: OnboardingProps) => {
  const { userId } = useCurrentUser();
  const { updateUser } = useUserUpdate();

  const handleNext = () => {
    if (isNative) {
      setActiveStep(OnboardingSteps.selectNotifications);
    } else {
      updateUser({ userId, data: { webOnboarded: true } });
    }
  };

  const handleBack = () => {
    if (skipPrivacyStep) {
      setActiveStep(OnboardingSteps.selectLanguage);
    } else {
      setActiveStep(OnboardingSteps.privacy);
    }
  };

  return (
    <OnboardingStep
      title={<FormattedMessage id="module_web_onboarding_topics_title" />}
      content={
        <Stack>
          <Typography variant="body2" sx={{ marginBottom: 2 }}>
            <FormattedMessage id="core_settings_topics_description" />
          </Typography>
          <NewsSubscriptionsForm />
        </Stack>
      }
      nextButtonText={
        isNative ? (
          <FormattedMessage id="core_general_next" />
        ) : (
          <FormattedMessage id="core_welcome_get_started" />
        )
      }
      onNext={handleNext}
      onBack={skipPrivacyStep && skipLanguageStep ? undefined : handleBack}
      banner={!isNative && <CookieBanner />}
    />
  );
};

export default SelectTopics;
