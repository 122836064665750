import { Button, Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import config from 'config';

import { isNative } from 'utils/capacitor.utils';

import { useCurrentUser, useUserUpdate } from 'queries';
import { getLocaleName } from 'services/i18n/utils.i18n';
import { intlActions } from 'store/intl/intl.slice';

import CookieBanner from 'components/@onboarding/CookieBanner';
import OnboardingStep from 'components/@onboarding/OnboardingStep';
import { OnboardingProps, OnboardingSteps } from 'components/@onboarding/types';

const SelectLanguage = ({ setActiveStep, skipPrivacyStep }: OnboardingProps) => {
  const dispatch = useDispatch();
  const { updateUser } = useUserUpdate();
  const { userId } = useCurrentUser();

  const handleSelectLanguage = (locale: string) => {
    dispatch(intlActions.updateLocale(locale));
    updateUser({ userId, data: { webLanguage: locale } });

    if (skipPrivacyStep) {
      setActiveStep(OnboardingSteps.selectTopics);
    } else {
      setActiveStep(OnboardingSteps.privacy);
    }
  };

  return (
    <OnboardingStep
      title={<FormattedMessage id="module_web_onboarding_language" />}
      content={
        <Stack pt={1} alignItems="flex-start" gap={{ xs: 3, lg: 2 }}>
          {config.LOCALES.available.map((locale) => (
            <Button
              key={locale}
              startIcon={
                <img
                  src={`/assets/flags/img_language_${locale}.png`}
                  width="24"
                  height="24"
                  alt={locale}
                />
              }
              variant="text"
              fullWidth
              sx={(theme) => ({
                justifyContent: 'flex-start',
                textAlign: 'left',
                padding: theme.spacing(1, 2),
                color: theme.palette.neutral01[600],
                borderRadius: '20px',
                width: 'fit-content',
              })}
              onClick={() => handleSelectLanguage(locale)}
            >
              <FormattedMessage id={getLocaleName(locale)} />
            </Button>
          ))}
        </Stack>
      }
      banner={!isNative && <CookieBanner />}
    />
  );
};

export default SelectLanguage;
