import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type NewsFilterState = {
  topics: Array<string>;
  hydrated: boolean;
};

const initialState: NewsFilterState = {
  topics: [],
  hydrated: false,
};

const newsFilterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setTopics: (state, { payload }: PayloadAction<Array<string>>) => {
      state.topics = payload;
    },
    resetTopics: (state) => {
      state.topics = [];
    },
    setHydrated: (state, { payload }: PayloadAction<boolean>) => {
      state.hydrated = payload;
    },
  },
});

export const { actions: newsFilterActions } = newsFilterSlice;

export default newsFilterSlice.reducer;
