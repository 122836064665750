import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { QueryKeys } from 'queries';
import { Duration } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { MeasurementsToken } from 'types/token.types';

const fetchMeasurementsToken = async () => {
  const { data: response } = await apiService.getMeasurementsToken();
  return response;
};

export const useMeasurementsToken = (
  config: UseCommonQueryOptions<
    MeasurementsToken,
    ReturnType<typeof QueryKeys.token.measurements>
  > = {},
) => {
  const { data, status, isLoading, error, refetch } = useQuery({
    queryKey: QueryKeys.token.measurements(),
    queryFn: () => fetchMeasurementsToken(),
    gcTime: Duration.FIFTEEN_MIN,
    staleTime: Duration.FIFTEEN_MIN,
    ...config,
  });

  useEffect(() => {
    if (!data) return;

    // Refresh token 10% before it expires
    const timeout = setTimeout(() => refetch(), data.expiresIn * 0.9 * 1000);

    return () => clearTimeout(timeout);
  }, [data, refetch]);

  return {
    data,
    status,
    isLoading,
    error,
    refetch,
  };
};
