import { alpha, Box, SxProps, Theme, useTheme } from '@mui/material';

import { useUnreadMessagesCount } from 'services/chat/hooks/useUnreadMessagesCount';

const UnreadMessageCounter = ({
  isActive,
  styles,
  unreadCount,
}: {
  isActive: boolean;
  styles?: SxProps<Theme> | undefined;
  unreadCount?: number;
}) => {
  const theme = useTheme();
  const { unreadMessagesCount } = useUnreadMessagesCount();

  // Step 1: Determine the count to display
  const displayCount = unreadCount ?? Number(unreadMessagesCount || 0);

  // Step 2: Check if the count is greater than zero
  if (displayCount <= 0) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 24,
        height: 24,
        borderRadius: '50%',
        backgroundColor: isActive
          ? theme.palette.brand.navigation.main[100]
          : alpha(theme.palette.brand.navigation.main.invert, 0.2),
        color: theme.palette.neutral01[100],
        fontSize: 11,
        fontWeight: 600,
        ...styles,
      }}
    >
      {unreadCount || unreadMessagesCount}
    </Box>
  );
};

export default UnreadMessageCounter;
