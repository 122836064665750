import { RefObject, useEffect, useMemo, useState } from 'react';

export const useIsVisible = (ref: RefObject<HTMLElement>, threshold?: number) => {
  const [isVisible, setIsVisible] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => setIsVisible(entry.isIntersecting), {
        // 75% of the item needs to be visible
        threshold: threshold ?? 0.75,
      }),
    [threshold],
  );

  useEffect(() => {
    if (!ref.current) return;
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [observer, ref]);

  return isVisible;
};
