import { Outlet } from 'react-router-dom';

import { createRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import newsPreview from 'pages/news/news-preview';

import { Modules } from 'components/@navigations/config';

import newsDetail from './news-detail';
import newsOverview from './news-overview';

export default createRoute({
  path: Routes.News,
  Component: Outlet,
  children: [newsOverview, newsDetail, newsPreview],
  moduleId: Modules.CompanyNews,
});
