import { alpha, Box, MenuItem, Typography } from '@mui/material';

import { isNative } from 'utils/capacitor.utils';

const MenuItemComponent = ({
  label,
  startIcon,
  action,
  isActive,
  removeBorderRadius,
}: {
  label?: string;
  startIcon?: React.ReactNode;
  action?: React.ReactNode;
  isActive?: boolean;
  removeBorderRadius?: boolean;
}) => {
  return (
    <MenuItem
      disableRipple={isNative}
      sx={(theme) => ({
        backgroundColor: isActive
          ? alpha(theme.palette.brand.navigation.main.invert, 0.2)
          : 'transparent',
        borderRadius: removeBorderRadius ? 0 : 4,
        color: {
          xs: theme.palette.neutral01[600],
          lg: theme.palette.brand.navigation.main.invert,
        },
        display: 'flex',
        justifyContent: 'space-between',
        transition: theme.transitions.create(['background-color']),
        py: action ? 0 : 1,
        pr: 1,
        height: 40,
      })}
    >
      <Box display="flex" width="100%" alignItems="center">
        <Box display="flex">{startIcon}</Box>

        <Typography ml={1} variant="overline" fontWeight="bold" noWrap>
          {label}
        </Typography>
      </Box>

      <Box display="flex">{action}</Box>
    </MenuItem>
  );
};

export default MenuItemComponent;
