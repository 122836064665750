import { Components, Theme } from '@mui/material';

export const MuiLink: Components<Theme>['MuiLink'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.neutral01[500],
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
    }),
  },
};
