import { RefObject, useContext, useEffect } from 'react';

import { useIsVisible } from 'hooks/useIsVisible';
import { MeasurementsEventPayload } from 'types/measurement.types';

import { MeasurementsContext } from './MeasurementsProvider';

export const useMeasurements = () => {
  return useContext(MeasurementsContext);
};

export const useImpressionEvent = (
  payload: MeasurementsEventPayload,
  ref: RefObject<HTMLElement>,
  enabled = true,
) => {
  const sendEvent = useMeasurements();
  const isVisible = useIsVisible(ref);

  // if the item is visible for more than 3 seconds, send an impression event
  useEffect(() => {
    if (!enabled || !isVisible) return;

    const timeout = setTimeout(() => sendEvent(payload), 3000);
    return () => clearTimeout(timeout);
  }, [enabled, isVisible, payload, sendEvent]);
};
