import { Box } from '@mui/material';
import { ReactNode } from 'react';

import { LoadingState } from 'components/@states';

type Props = {
  children?: ReactNode;
  isLoading: boolean;
};

const TRANSITION = 'opacity 0.25s ease-in-out';

// Component is used to show a loading state while we are fetching required data,
// Don't render children until we are done loading, since they may depend on the data
const Splash = ({ children, isLoading }: Props) => {
  return (
    <>
      <Box
        alignItems="center"
        justifyContent="center"
        position="fixed"
        height="100vh"
        width="100vw"
        sx={{
          transition: TRANSITION,
          opacity: isLoading ? 1 : 0,
          transitionDelay: '1.5s',
          '@supports (height: 100svh)': {
            height: '100svh',
          },
          '@supports (width: 100svw)': {
            width: '100svw',
          },
        }}
      >
        <LoadingState />
      </Box>
      <Box
        position="relative"
        sx={{ transition: TRANSITION, opacity: isLoading ? 0 : 1, transitionDelay: '1.75s' }}
        zIndex={1}
      >
        {isLoading ? null : children}
      </Box>
    </>
  );
};

export default Splash;
