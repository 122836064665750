import { Components, Theme } from '@mui/material';

export const MuiYearCalendar: Components<Theme>['MuiYearCalendar'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      '& .MuiPickersYear-yearButton': {
        ...theme.typography.body2,
        '&.Mui-selected': {
          '&:hover, &:focus, &:active': {
            backgroundColor: theme.palette.brand.main[100],
          },
        },
      },
    }),
  },
};
