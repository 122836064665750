import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { QueryKeys } from 'queries';
import { Duration } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { User } from 'types/user.types';

const fetchUser = async (userId: User['spencerUuid']) => {
  const { data: response } = await apiService.getUser(userId);
  return response;
};

export const useUser = (
  userId: User['spencerUuid'],
  config: UseCommonQueryOptions<
    User,
    AxiosError,
    User,
    ReturnType<typeof QueryKeys.users.byId>
  > = {},
) => {
  const query = useQuery({
    queryKey: QueryKeys.users.byId(userId),
    queryFn: () => fetchUser(userId),
    ...config,
    meta: { store: true },
    staleTime: config?.staleTime ?? Duration.FIFTEEN_MIN,
  });

  return {
    isSuccess: query.isSuccess,
    data: query.data,
    isLoading: query.isLoading,
    error: query.error,
    isError: query.isError,
    refetch: query.refetch,
  };
};
