import { Collection } from 'types/api.types';
import { SearchParams, SearchResult } from 'types/search.types';

import RestApiService from '.';

export default {
  search(this: RestApiService, params: SearchParams) {
    return this.api.get<Collection<Array<SearchResult>>>(`/api/search`, { params });
  },
};
