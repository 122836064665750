import { Components, Theme } from '@mui/material';

export const MuiButton: Components<Theme>['MuiButton'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      textTransform: 'none',
      color: theme.palette.brand.main[100],
      borderRadius: theme.shape.borderRadius * 3,
      [':not(.MuiLoadingButton-loading).Mui-disabled']: {
        backgroundColor: theme.palette.neutral01[400],
      },
      transition: theme.transitions.create(['filter', 'background-color']),
      '&:hover:not(.Mui-disabled)': {
        filter: 'brightness(1.1)',
      },
      '&:active:not(.Mui-disabled)': {
        filter: 'brightness(1.2)',
      },
      ['& .MuiLoadingButton-loadingIndicator']: {
        color: theme.palette.neutral01[100],
      },
    }),
    sizeLarge: ({ theme }) => ({
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(1.5),
      paddingTop: theme.spacing(1.5),
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '24px',
    }),
    textSizeSmall: () => ({
      fontSize: '12.8px',
      lineHeight: '16px',
      fontWeight: 700,
      '& .MuiButton-iconSizeSmall svg': {
        height: 16,
        width: 16,
      },
    }),
    textSizeMedium: () => ({
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 700,
    }),
    text: ({ theme }) => ({
      color: theme.palette.neutral01[800],
    }),
    outlinedSizeSmall: () => ({
      fontSize: '12.8px',
      lineHeight: '16px',
      fontWeight: 700,
      '& .MuiButton-iconSizeSmall svg': {
        height: 16,
        width: 16,
      },
    }),
    outlinedSizeMedium: () => ({
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 700,
    }),
  },
  variants: [
    {
      props: { variant: 'main' },
      style: ({ theme }) => ({
        fontSize: '16px',
        fontWeight: 700,
        backgroundColor: theme.palette.brand.action.main[100],
        color: theme.palette.brand.action.main.invert,
        '&:hover': {
          backgroundColor: theme.palette.brand.action.main[100],
        },
      }),
    },
    {
      props: { variant: 'contrast' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.brand.action.contrast[100],
        color: theme.palette.brand.action.contrast.invert,
        '&:hover': {
          backgroundColor: theme.palette.brand.action.contrast[100],
        },
      }),
    },
    {
      props: { variant: 'destructive' },
      style: ({ theme }) => ({
        fontSize: '16px',
        fontWeight: 700,
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        '&:hover': {
          backgroundColor: theme.palette.error.main,
        },
      }),
    },
  ],
};
