import { alpha, ThemeOptions } from '@mui/material';

export const palette: ThemeOptions['palette'] = {
  background: {
    default: '#FFFFFF',
    paper: '#FFFFFF',
  },
  primary: {
    main: '#0090FF',
    light: alpha('#0090FF', 0.1),
  },
  text: {
    primary: '#000000',
  },
  neutral01: {
    100: '#FFFFFF',
    200: '#FAFBFC',
    300: '#F2F7FA',
    400: '#E6EDF2',
    500: '#9BA2A8',
    600: '#71777C',
    700: '#25282B',
    800: '#000000',
  },
  reaction: {
    like: '#0090FF',
    love: '#EE2E5D',
    inspired: '#F5A623',
    celebrate: '#3ACD7F',
    curious: '#A97AD5',
  },

  brand: {
    main: { 100: '#0090FF', 10: alpha('#0090FF', 0.2), invert: '#FFFFFF' },
    // highlight: {
    //   100: '#0090FF',
    //   10: alpha('#0090FF', 0.2),
    //   invert: '#FFFFFF',
    // },
    greeting: '#0090FF',
    action: {
      main: {
        100: '#0090FF',
        invert: '#FFFFFF',
      },
      contrast: {
        100: '#2A292E',
        invert: '#FFFFFF',
      },
      articleLink: {
        100: '#0090FF',
        invert: '#FFFFFF',
      },
    },
    navigation: {
      main: {
        100: '#0090FF',
        invert: '#FFFFFF',
      },
    },
    quote: {
      article: {
        icon: alpha('#0090FF', 0.2),
        text: '#0090FF',
      },
    },
    title: {
      news: {
        100: '#000',
      },
      forms: {
        100: '#0090FF',
        invert: '#FFFFFF',
      },
      onboarding: {
        100: '#0090FF',
        invert: '#FFFFFF',
      },
    },
    pollCard: {
      overview: {
        100: '#f5a623',
        invert: '#FFFFFF',
        chip: {
          100: '#FFFFFF',
          10: alpha('#FFFFFF', 0.2),
          invert: '#FFFFFF',
        },
        result: {
          100: '#2a292e',
          invert: '#FFFFFF',
        },
      },
    },
    pollTitle: {
      detail: {
        100: '#f5a623',
        invert: '#FFFFFF',
      },
    },
    siteCard: {
      detail: {
        100: '#0090FF',
        invert: '#FFFFFF',
      },
    },
    topicChip: {
      image: {
        100: '#FFFFFF',
        invert: '#0090FF',
      },
      selection: {
        100: '#0090FF',
        10: '#0090FF',
        invert: '#FFFFFF',
      },
      selectionBlock: {
        100: '#0090FF',
        invert: '#FFFFFF',
      },
      textHover: {
        100: '#000',
        invert: '#FFFFFF',
      },
      defaultCard: {
        100: '#0090FF',
        10: alpha('#0090FF', 0.2),
        invert: '#0090FF',
      },
      news: {
        100: '#0090FF',
        invert: '#FFFFFF',
      },
    },
    pollResult: {
      overview: {
        100: '#f5a623',
        invert: '#FFFFFF',
      },
    },
    surveyCard: {
      overview: {
        100: '#f5a623',
        invert: '#FFFFFF',
        chip: {
          100: '#FFFFFF',
          10: alpha('#FFFFFF', 0.2),
          invert: '#FFFFFF',
        },
      },
    },
    articleCard: {
      highlighted: {
        100: '#0090FF',
        invert: '#FFFFFF',
        action: '#FFFFFF',
        topic: {
          100: '#FFFFFF',
          invert: '#FFFFFF',
        },
      },
      important: {
        100: '#EE2E5D',
        invert: '#FFFFFF',
      },
    },
    profileCard: {
      detail: {
        100: '#0090FF',
        invert: '#FFFFFF',
      },
    },
    surveyTitle: {
      detail: {
        100: '#f5a623',
        invert: '#FFFFFF',
      },
    },
  },

  error: {
    main: '#EE2E5D',
    light: alpha('#EE2E5D', 0.1),
    contrastText: '#FFFFFF',
  },
  success: {
    main: '#3ACC7F',
    light: alpha('#3ACC7F', 0.1),
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#F5A623',
    light: alpha('#F5A623', 0.1),
    contrastText: '#FFFFFF',
  },
};
