import { Suspense, useMemo } from 'react';
import { RouterProvider } from 'react-router-dom';

import { sentryCreateBrowserRouter } from 'services/sentry/web-sentry';

import { useAllRoutes } from './routes';

const Pages = () => {
  const allRoutes = useAllRoutes();
  const router = useMemo(() => sentryCreateBrowserRouter([allRoutes]), [allRoutes]);

  return (
    <Suspense>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default Pages;
