import { Outlet } from 'react-router-dom';

import { createRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import { Modules } from 'components/@navigations/config';

import documentsDetail from './documents-detail';
import documentsFavourites from './documents-favourites';
import documentsFolder from './documents-folder';
import documentsRoot from './documents-root';

export default createRoute({
  path: Routes.Documents,
  Component: Outlet,
  children: [documentsRoot, documentsFolder, documentsDetail, documentsFavourites],
  moduleId: Modules.DocumentLibrary,
});
