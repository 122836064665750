import { Components, Theme } from '@mui/material';

export const MuiAutocomplete: Components<Theme>['MuiAutocomplete'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ['& .MuiOutlinedInput-root.MuiInputBase-sizeSmall']: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
      },
      ['& .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input']: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    }),
    option: ({ theme }) => ({
      ':hover': {
        backgroundColor: theme.palette.brand.main[10],
        color: theme.palette.brand.main[100],
      },
    }),
    listbox: ({ theme }) => ({
      maxHeight: '100%',
      ['& .MuiAutocomplete-option']: {
        paddingLeft: `${theme.spacing(3)}`,
        paddingRight: `${theme.spacing(3)}`,
        paddingTop: `${theme.spacing(1)}`,
        paddingBottom: `${theme.spacing(1)}`,
      },
      ['& .MuiAutocomplete-option[aria-selected="true"]']: {
        color: theme.palette.brand.main[100],
        backgroundColor: theme.palette.brand.main[10],
      },
      ['& .MuiAutocomplete-option.Mui-focused']: {
        backgroundColor: theme.palette.brand.main[10],
        color: theme.palette.brand.main[100],
      },
      'li:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },
    }),
  },
};
