import { Components, Theme } from '@mui/material';

export const MuiPickersDay: Components<Theme>['MuiPickersDay'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.body2,
      '&.Mui-selected': {
        backgroundColor: theme.palette.brand.main[100],
        '&:hover, &:focus, &:active': {
          backgroundColor: theme.palette.brand.main[100],
        },
      },
      '&.Mui-disabled:not(.Mui-selected)': {
        color: theme.palette.neutral01[500],
      },
    }),
  },
};
