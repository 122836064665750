import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from 'queries';
import { Duration } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { fetchTenantConfig } from 'services/tenant/TenantGate/fetchTenantConfig';
import { EnvTenant } from 'types/env.types';

export const useTenantConfig = (
  config: UseCommonQueryOptions<EnvTenant, ReturnType<typeof QueryKeys.tenants.initial>> = {},
) => {
  const { data, isLoading, isSuccess, isError, error } = useQuery({
    queryKey: QueryKeys.tenants.initial(),
    queryFn: () => fetchTenantConfig(),
    staleTime: Duration.FOREVER,
    select: (data) => ({
      ...data,
      configuration: {
        ...data.configuration,
        // don't allow zoom to be set to 0
        zoom:
          !data.configuration.zoom || data.configuration.zoom === '0'
            ? '1'
            : data.configuration.zoom,
      },
    }),
    ...config,
  });

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    error,
  };
};
