import { useContext, useLayoutEffect } from 'react';

import DefaultHeader from 'components/@navbar/DefaultHeader/DefaultHeader';

import NavbarContext from './NavbarContext';

interface Props {
  children: React.ReactNode;
}

/**
 *    This component can be used to replace the header for the current page
 */
const Header = ({ children }: Props) => {
  const { setHeader } = useContext(NavbarContext);

  useLayoutEffect(() => {
    setHeader(children || <DefaultHeader />);

    // Reset header to default when component unmounts
    return () => setHeader(<DefaultHeader />);
  }, [children, setHeader]);

  return null;
};

export default Header;
