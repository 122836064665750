import { Components, Theme } from '@mui/material';

export const MuiTypography: Components<Theme>['MuiTypography'] = {
  defaultProps: {
    variantMapping: {
      overline: 'p',
      caption: 'p',
    },
  },
  variants: [
    {
      props: { variant: 'overline' },
      style: {
        textTransform: 'none',
      },
    },
  ],
};
