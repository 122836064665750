import { Components, Theme } from '@mui/material';

export const MuiCard: Components<Theme>['MuiCard'] = {
  defaultProps: {
    variant: 'outlined',
  },
  variants: [
    {
      props: { variant: 'outlined' },
      style: ({ theme }) => ({
        borderRadius: theme.shape.borderRadius * 4,
        borderColor: theme.palette.neutral01[400],
      }),
    },
  ],
};
