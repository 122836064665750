import { createRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import { Modules } from 'components/@navigations/config';

export default createRoute({
  path: Routes.Links,
  lazy: () => import('./Links'),
  moduleId: Modules.LinkLibrary,
});
