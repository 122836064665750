import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { createIntl, createIntlCache, IntlShape } from 'react-intl';

import config from 'config';

import { isWeb } from 'utils/capacitor.utils';
import { isDev } from 'utils/env.utils';

import { QueryKeys } from 'queries';
import { Duration } from 'queries/constants';
import { replacePlaceholderBrackets } from 'services/i18n/utils.i18n';

const intlCache = createIntlCache();

const fetchLocales = async () => {
  const locales = [...new Set([config.LOCALES.default, ...config.LOCALES.available])];

  // Fetch all the locales in parallel and create the intl object
  const localeFiles = await Promise.all(
    locales.map(async (locale) => {
      const prefix =
        window.location.host.includes('localhost') || isDev ? config.DEV_LOCALE_BASE_URL : '';
      const webUrl = `${prefix}/translations/${locale}.json?v=${config.BUILD_HASH}`;
      const nativeUrl = `/app/translations/${locale}.json`;

      const url = isWeb ? webUrl : nativeUrl;

      const response = await axios.get<Record<string, string>>(url);

      return {
        locale,
        messages: replacePlaceholderBrackets(response.data),
      };
    }),
  );

  const intl = localeFiles.reduce<{ [key: string]: IntlShape }>(
    (acc, { locale, messages }) => ({
      ...acc,
      [locale]: createIntl({ locale, messages }, intlCache),
    }),
    {},
  );
  return intl;
};

export const useInitialiseLocales = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: QueryKeys.configuration.locales(),
    queryFn: fetchLocales,
    staleTime: Duration.FOREVER,
  });

  return {
    intl: data,
    isLoading,
    isError,
  };
};
