import { createContext, ReactNode, useContext } from 'react';

import useCapacitorDeviceInfo, {
  DeviceInfo,
  initialDeviceInfo,
} from 'services/capacitor/hooks/useCapacitorDeviceInfo';

const DeviceInfoContext = createContext<DeviceInfo>(initialDeviceInfo);

interface Props {
  children: ReactNode;
}

export const DeviceInfoProvider = ({ children }: Props) => {
  const deviceInfo = useCapacitorDeviceInfo();

  if (!deviceInfo) return null;

  return <DeviceInfoContext.Provider value={deviceInfo}>{children}</DeviceInfoContext.Provider>;
};

export const useDeviceInfo = () => useContext(DeviceInfoContext);
