import { Box, useTheme } from '@mui/material';

import { useUnreadMessagesCount } from 'services/chat/hooks/useUnreadMessagesCount';

import Icon from 'components/@icons';

// INFO: Show or hide badge if there are unread messages
const IconMessageWithBadge = () => {
  const theme = useTheme();
  const { hasUnreadMessages } = useUnreadMessagesCount();
  return (
    <Box position="relative" display="flex">
      <Icon name="IcoMessage" />
      {hasUnreadMessages && (
        <Box
          position="absolute"
          top="-0.1rem"
          right="-0.2rem"
          width="0.7rem"
          height="0.7rem"
          borderRadius="50%"
          border={2}
          borderColor={theme.palette.common.white}
          bgcolor={theme.palette.brand.main[100]}
        />
      )}
    </Box>
  );
};

export default IconMessageWithBadge;
