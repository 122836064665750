import { connect, StreamClient } from 'getstream';
import { memoize } from 'lodash';

import config from 'config';

const getStreamClientFun = (accessToken: string): StreamClient | null => {
  if (!accessToken) {
    console.warn('Access token is missing for Stream client creation');
    return null;
  }
  return connect(config.STREAM_API_KEY, accessToken, config.STREAM_APP_ID);
};

const getStreamFeedFun = (accessToken: string, feedSlug: string, userId: string) => {
  const client = getStreamClientFun(accessToken);
  return client?.feed(feedSlug, userId);
};

export const getStreamFeed = memoize(getStreamFeedFun);
export const getStreamClient = memoize(getStreamClientFun);
