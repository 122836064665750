import { EventDataBatch, EventHubProducerClient } from '@azure/event-hubs';
import { Capacitor } from '@capacitor/core';
import { decamelizeKeys } from 'humps';
import { createContext, useCallback, useEffect, useRef, useState } from 'react';

import config from 'config';

import { appVersion, isDev } from 'utils/env.utils';

import { useCurrentUser, useMeasurementsToken } from 'queries';
import { useSelectedLocale } from 'services/i18n';
import { MeasurementsEventPayload } from 'types/measurement.types';

const BATCH_BUFFER_MS = 5000;

export const MeasurementsContext = createContext<
  (payload: MeasurementsEventPayload) => Promise<void>
>(() => Promise.resolve());

const MeasurementsProvider = ({ children }: { children: React.ReactNode }) => {
  const [logging, setLogging] = useState(false);
  const { data: token } = useMeasurementsToken();
  const { user } = useCurrentUser();
  const client = useRef<EventHubProducerClient>();
  const batch = useRef<EventDataBatch>();
  const { trimmedLocale } = useSelectedLocale();

  // Enable logging by calling `logs()` in the console
  window.logs = function () {
    setLogging(true);
    return 'Logging enabled';
  };

  useEffect(() => {
    if (!token) return;

    const newClient = new EventHubProducerClient(
      token.namespace,
      token.eventHubName,
      {
        getToken: async () => ({ token: token.token, expiresOnTimestamp: token.expiresAt * 1000 }),
      },
      { retryOptions: { maxRetries: 5, retryDelayInMs: 1000 } },
    );

    client.current = newClient;
  }, [token]);

  const sendBatch = useCallback(async () => {
    try {
      if (!batch.current) return;

      const currentBatch = batch.current;
      batch.current = undefined;

      if (isDev) return;
      await client.current?.sendBatch(currentBatch);

      if (logging) {
        console.info('EventHub: batch sent, size', currentBatch.count);
      }
    } catch (error) {
      if (logging) {
        console.error('EventHub: error in sendBatch:', error);
      }
    }
  }, [logging]);

  useEffect(() => {
    const interval = setInterval(sendBatch, BATCH_BUFFER_MS);
    return () => clearInterval(interval);
  }, [sendBatch]);

  const sendEvent = useCallback(
    async ({ entity, eventName, data }: MeasurementsEventPayload) => {
      try {
        // if (!featureFlags.isMeasurementsEnabled()) return;
        if (!client.current) return;

        const defaultBody = {
          publisherId: Capacitor.getPlatform(),
          publisherVersion: appVersion,
          customer: config.COMPANY_ID,
          serviceUuid: user?.serviceUuid,
          language: trimmedLocale,
        };

        const body = decamelizeKeys({
          entity,
          eventName,
          data,
          eventDateTime: new Date().toISOString(),
          ...defaultBody,
        });

        if (!batch.current) {
          batch.current = await client.current.createBatch({});
        }

        if (logging) {
          console.info(
            'EventHub: event added to batch:\n',
            `${entity} - ${eventName}\n`,
            decamelizeKeys(data),
          );
        }

        // if the current batch is already full, we send it and add this event to a new batch by calling sendEvent again
        if (!batch.current.tryAdd({ body })) {
          await sendBatch();
          await sendEvent({ entity, eventName, data });
        }
      } catch (error) {
        if (logging) {
          console.error('EventHub: error in sendEvent:', error);
        }
      }
    },
    [logging, sendBatch, trimmedLocale, user?.serviceUuid],
  );

  return <MeasurementsContext.Provider value={sendEvent}>{children}</MeasurementsContext.Provider>;
};

export default MeasurementsProvider;
