import { useInfiniteQuery } from '@tanstack/react-query';

import { QueryKeys } from 'queries';
import { Duration } from 'queries/constants';
import {
  defaultGetNextPageParam,
  defaultInitialPageParam,
} from 'queries/helpers/createInfiniteQuery';
import { apiService } from 'services';
import { useSelectedLocale } from 'services/i18n';
import { LinkParams } from 'types/links.types';

const fetchLinks = async (params: LinkParams) => {
  const { data: response } = await apiService.getLinks(params);
  return response;
};

export const useInfiniteLinks = (params: LinkParams) => {
  const { locale } = useSelectedLocale();

  const { fetchNextPage, isLoading, hasNextPage, isFetchingNextPage, data } = useInfiniteQuery({
    queryKey: QueryKeys.links.all(locale, params),
    initialPageParam: defaultInitialPageParam,
    queryFn: ({ pageParam }) => {
      return fetchLinks({ p: Number(pageParam), ...params });
    },
    getNextPageParam: defaultGetNextPageParam,
    staleTime: Duration.FOREVER,
  });

  return {
    data: data?.pages.flatMap((page) => page.data ?? []) ?? [],
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isLoading,
  };
};
