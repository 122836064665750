import { Box, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import config from 'config';

import { gradientAnimation, highlightGradient } from 'utils/styles.utils';

import { useMedia } from 'hooks/useMedia';

import Icon from 'components/@icons/Icon';

import { useTenantHasUpdates } from './TenantUpdateProvider';

const TenantUpdateFooter = () => {
  const { hasUpdates, reload } = useTenantHasUpdates();
  const { isDesktop } = useMedia();

  if (!hasUpdates || isDesktop) return null;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={1.5}
      sx={(theme) => ({
        height: theme.mixins.footer.height,
        cursor: 'pointer',
        transition: theme.transitions.create(['background-color']),
        position: 'relative',
        background: highlightGradient(theme.palette.brand.main[100]) + ', #FFFFFF',
        ':hover': { ':before': { opacity: 1 } },
        ':active': { ':after': { opacity: 1 } },
        '&:before': {
          ...gradientAnimation,
          background: highlightGradient(theme.palette.brand.main[100], 0.1),
        },
        '&:after': {
          ...gradientAnimation,
          background: highlightGradient(theme.palette.brand.main[100], 0.2),
        },
      })}
      onClick={reload}
    >
      <Stack gap={0.5} sx={{ wordBreak: 'break-word' }}>
        <Typography
          variant="overline"
          fontWeight={700}
          sx={(theme) => ({ color: theme.palette.brand.main[100] })}
        >
          <FormattedMessage
            id="core_update_available_alternative"
            values={{ app_name: config.NAME }}
          />
        </Typography>

        <Typography variant="caption" sx={(theme) => ({ color: theme.palette.brand.main[100] })}>
          <FormattedMessage id="core_update_refresh" />
        </Typography>
      </Stack>
      <Icon
        name="IcoRefresh"
        className="rotate-icon"
        sx={(theme) => ({
          color: theme.palette.brand.main[100],
          '&.rotate-icon': {
            WebkitAnimation: 'rotate 2s ease-in-out infinite',
          },
        })}
      />
    </Box>
  );
};

export default TenantUpdateFooter;
