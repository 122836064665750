import { useContext, useLayoutEffect } from 'react';

import { FooterContainer } from 'components/@navbar';
import { DefaultFooter } from 'components/@navbar/DefaultFooter';

import NavbarContext from './NavbarContext';

interface Props {
  children?: React.ReactNode;
}

// This component can be used to set the footer for the current page
const Footer = ({ children }: Props) => {
  const { setFooter } = useContext(NavbarContext);

  useLayoutEffect(() => {
    setFooter(<FooterContainer>{children || <DefaultFooter />}</FooterContainer>);

    // Reset footer to default when component unmounts
    return () =>
      setFooter(
        <FooterContainer>
          <DefaultFooter />
        </FooterContainer>,
      );
  }, [children, setFooter]);

  return null;
};

export default Footer;
