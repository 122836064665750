import { Collection, Href, Pagination } from 'types/api.types';
import { NewsItemType } from 'types/news.types';
import type {
  BookmarkParams,
  User,
  UserFeedbackParams,
  UserProfilePicturePostParams,
  UserUpdateParams,
} from 'types/user.types';

import RestApiService from '.';

export default {
  getUsers(this: RestApiService, params: Pagination) {
    return this.api.get<Collection<Array<Href>>>('/api/users', {
      params,
    });
  },
  getUser(this: RestApiService, userId: User['spencerUuid']) {
    return this.api.get<User>(`/api/users/${userId}`);
  },
  putUser(this: RestApiService, userId: User['spencerUuid'], params: UserUpdateParams) {
    return this.api.put<User>(`/api/users/${userId}`, params);
  },
  postUserProfilePicture(
    this: RestApiService,
    userId: User['spencerUuid'],
    params: UserProfilePicturePostParams,
  ) {
    return this.api.post(`/api/users/${userId}/profile-picture`, params);
  },
  getUserProfilePicture(this: RestApiService, userId: User['spencerUuid']) {
    return this.api.get(`/api/users/${userId}/profile-picture`);
  },
  postUserFeedback(this: RestApiService, userId: User['spencerUuid'], params: UserFeedbackParams) {
    return this.api.post(`/api/users/${userId}/feedback`, params);
  },
  getUserColleagues(this: RestApiService, userId: User['spencerUuid'], params: Pagination) {
    return this.api.get<Collection<Array<Href>>>(`/api/users/${userId}/colleagues`, {
      params,
    });
  },
  getUserTeam(this: RestApiService, userId: User['spencerUuid'], params: Pagination) {
    return this.api.get<Collection<Array<Href>>>(`/api/users/${userId}/team`, {
      params,
    });
  },
  createBookmark(
    this: RestApiService,
    userId: User['spencerUuid'],
    newsItemId: NewsItemType['id'],
  ) {
    return this.api.post(`api/users/${userId}/news/bookmarks`, {
      publication: newsItemId,
    });
  },
  deleteBookmark(
    this: RestApiService,
    userId: User['spencerUuid'],
    newsItemId: NewsItemType['id'],
  ) {
    return this.api.delete(`api/users/${userId}/news/bookmarks/${newsItemId}`);
  },
  getBookmarks(this: RestApiService, userId: User['spencerUuid'], params: BookmarkParams) {
    return this.api.get<Collection<Array<Href>>>(`api/users/${userId}/news/bookmarks`, {
      params,
    });
  },
  checkBookmarked(
    this: RestApiService,
    userId: User['spencerUuid'],
    newsItemId: NewsItemType['id'],
  ) {
    return this.api.head(`api/users/${userId}/news/bookmarks/${newsItemId}`);
  },
};
