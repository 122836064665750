import { Box, styled } from '@mui/material';

export const PullToRefreshStyled = styled(Box)(({ theme }) => {
  return {
    '.ptr': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '.ptr, .ptr__children': {
      overflow: 'unset',
    },
    '.ptr__pull-down': {
      display: 'flex',
      justifyContent: 'center',
    },
    '.lds-ellipsis div': {
      background: theme.palette.brand.main[100],
    },
  };
});
