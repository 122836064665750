import {
  BrowserCacheLocation,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { ReactNode, useState } from 'react';

import config from 'config';

import { isNative } from 'utils/capacitor.utils';

import { Routes } from 'constants/routes.constants';
import { useOnMountUnsafe } from 'hooks/useOnMountUnsafe';

import { Splash } from 'components/@splash';

import IdentityConsumer from './IdentityConsumer';

interface Props {
  children: ReactNode;
}

const getClientId = () =>
  isNative ? config.IDENTITY.client_ids.capacitor : config.IDENTITY.client_ids.web;

const getAppIdentifier = () => (isNative ? App.getInfo() : null);

const getRedirectUri = (isBeta: boolean) => {
  const platform = Capacitor.getPlatform();

  if (platform !== 'android' && platform !== 'ios') return window.location.origin;
  if (isBeta) return config.REDIRECT_URIS?.[platform]?.beta;
  return config.REDIRECT_URIS?.[platform]?.release;
};

const getPostLogoutRedirectUri = (isBeta: boolean) => {
  const platform = Capacitor.getPlatform();

  if (platform !== 'android' && platform !== 'ios') return Routes.News;
  if (isBeta) return config.REDIRECT_URIS?.[platform]?.beta;
  return config.REDIRECT_URIS?.[platform]?.release;
};

const IdentityGate = ({ children }: Props) => {
  const [pca, setPca] = useState<IPublicClientApplication | null>(null);

  useOnMountUnsafe(
    {
      check: !pca && !!config.IDENTITY.issuer_url,
      callback: () => {
        const initPublicClientApplication = async () => {
          const authorityUrl = config.IDENTITY.issuer_url.replace('v2.0', '');
          const redirectUri = await getAppIdentifier();
          const appIdentifier = redirectUri?.id ?? '';
          const client = await PublicClientApplication.createPublicClientApplication({
            auth: {
              clientId: getClientId(),
              authority: authorityUrl,
              knownAuthorities: [new URL(authorityUrl).hostname],
              redirectUri: getRedirectUri(appIdentifier.includes('beta')) ?? '',
              postLogoutRedirectUri: getPostLogoutRedirectUri(appIdentifier.includes('beta')) ?? '',
            },
            system: {
              // This is needed to allow the login to work in an iframe
              // Attempt to fix sentry issue, github link: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2531
              //allowRedirectInIframe: true,
              allowNativeBroker: true,
              // debug the identity service, we can enable the logger
              // loggerOptions: {
              //   loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
              //     if (containsPii) {
              //       return;
              //     }
              //     switch (level) {
              //       case LogLevel.Error:
              //         console.error(message);
              //         return;
              //       case LogLevel.Info:
              //         console.info(message);
              //         return;
              //       case LogLevel.Verbose:
              //         console.info(message);
              //         return;
              //       case LogLevel.Warning:
              //         console.warn(message);
              //         return;
              //     }
              //   },
              //   piiLoggingEnabled: true,
              // },
            },
            cache: { cacheLocation: BrowserCacheLocation.LocalStorage },
          });
          await client.initialize();
          setPca(client);
        };
        initPublicClientApplication();
      },
    },
    [],
  );

  if (!pca) return <Splash isLoading />;

  return (
    <MsalProvider instance={pca}>
      <IdentityConsumer scopes={config.IDENTITY.scopes}>{children}</IdentityConsumer>
    </MsalProvider>
  );
};

export default IdentityGate;
