import { EnvTenant, Locales, TenantTheme } from 'types/env.types';
import { FeatureFlags } from 'types/features.types';
import { DeepPartial } from 'types/helper.types';

interface EnvBase {
  DEFAULT_LOCALE: string;
  TENANT_CONFIG_URL: string;
  DEV_LOCALE_BASE_URL: string;
  VERSION: string;
  BUILD_ENV: string;
  SPENCER_ENV: string;
  ENABLE_DEV_TOOLS: boolean;
  LOCAL_STORAGE_ID: string;
  REACT_QUERY_LOCAL_STORAGE_ID: string;
  SENTRY: {
    dsn?: string;
    environment?: string;
    release?: string;
  };
  NATIVE_SENTRY: {
    dsn?: string;
    environment?: string;
    release?: string;
  };
  BUILD_HASH: string;
}

class EnvService {
  DEFAULT_LOCALE = '';
  TENANT_CONFIG_URL = '';
  DEV_LOCALE_BASE_URL = '';
  VERSION = '';
  BUILD_ENV = '';
  SPENCER_ENV = '';
  ENABLE_DEV_TOOLS = false;
  LOCAL_STORAGE_ID = '';
  REACT_QUERY_LOCAL_STORAGE_ID = '';
  SENTRY = { dsn: '', environment: '', release: '' } as EnvBase['SENTRY'];
  NATIVE_SENTRY = { dsn: '', environment: '', release: '' } as EnvBase['NATIVE_SENTRY'];
  NAME = '';
  API_BASE = '';
  STREAM_API_KEY = '';
  STREAM_APP_ID = '';
  COMPANY_NAME = '';
  COMPANY_ID = '';
  WEB_HOST = '';
  BUILD_HASH = '';
  MOBILE_APPS = { android: null, ios: null } as EnvTenant['configuration']['mobileApps'];
  THEME = {} as DeepPartial<TenantTheme>;
  FEATURES = {} as Record<FeatureFlags, boolean>;
  LOCALES = {} as Locales;
  IDENTITY = {} as EnvTenant['identity'];
  ASSETS = {} as EnvTenant['configuration']['assets'];
  REDIRECT_URIS = {
    android: {
      beta: null,
      release: null,
    },
    ios: {
      beta: null,
      release: null,
    },
  } as EnvTenant['configuration']['mobile']['identityRedirectUris'];
  PLATFORM_ARN = {} as EnvTenant['configuration']['mobile']['snsArnPrefix'];
  PLATFORM_APPLICATION_NAME = {} as EnvTenant['configuration']['mobile']['snsApplicationName'];
  IDENTITY_POOL = {} as EnvTenant['configuration']['mobile']['cognitoIdentityPoolId'];

  TENANT_CONFIG = {} as EnvTenant;

  constructor(values: Partial<EnvBase>) {
    this.DEFAULT_LOCALE = values.DEFAULT_LOCALE ?? '';
    this.TENANT_CONFIG_URL = values.TENANT_CONFIG_URL || this.TENANT_CONFIG_URL;
    this.DEV_LOCALE_BASE_URL = values.DEV_LOCALE_BASE_URL || this.DEV_LOCALE_BASE_URL;
    this.VERSION = values.VERSION || this.VERSION;
    this.BUILD_ENV = values.BUILD_ENV || this.BUILD_ENV;
    this.SPENCER_ENV = values.SPENCER_ENV || this.SPENCER_ENV;
    this.ENABLE_DEV_TOOLS = values.ENABLE_DEV_TOOLS || this.ENABLE_DEV_TOOLS;
    this.LOCAL_STORAGE_ID = values.LOCAL_STORAGE_ID || this.LOCAL_STORAGE_ID;
    this.REACT_QUERY_LOCAL_STORAGE_ID =
      values.REACT_QUERY_LOCAL_STORAGE_ID || this.REACT_QUERY_LOCAL_STORAGE_ID;
    this.SENTRY = values.SENTRY || this.SENTRY;
    this.NATIVE_SENTRY = values.NATIVE_SENTRY || this.NATIVE_SENTRY;
    this.BUILD_HASH = values.BUILD_HASH || this.VERSION;
  }

  setTenantConfig(values: EnvTenant) {
    this.NAME = values.configuration.name;
    this.ASSETS = values.configuration.assets;
    this.API_BASE = `https://${values.apihost}`;
    this.THEME = values.configuration.theme;
    this.FEATURES = values.configuration.features;
    this.WEB_HOST = `https://${values.webhost}`;
    this.LOCALES = values.configuration.locales;
    this.STREAM_API_KEY = values.configuration.stream.key;
    this.STREAM_APP_ID = values.configuration.stream.id;
    this.IDENTITY = values.identity;
    this.COMPANY_NAME = values.configuration.company.name;
    this.COMPANY_ID = values.configuration.company.id;
    this.MOBILE_APPS = values.configuration.mobileApps;
    this.REDIRECT_URIS = values.configuration.mobile.identityRedirectUris;
    this.PLATFORM_ARN = values.configuration.mobile.snsArnPrefix;
    this.PLATFORM_APPLICATION_NAME = values.configuration.mobile.snsApplicationName;
    this.IDENTITY_POOL = values.configuration.mobile.cognitoIdentityPoolId;
    this.TENANT_CONFIG = values;
  }
}

export default EnvService;
