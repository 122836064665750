import { Box, IconButton, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { lineClamp } from 'utils/styles.utils';

import { Routes } from 'constants/routes.constants';
import { searchActions } from 'store/search/search.slice';

import Icon from 'components/@icons/Icon';
import { HeaderContainer } from 'components/@navbar';
import { HideOnScroll } from 'components/@navigations';

interface Props {
  title?: ReactNode;
  actions?: ReactNode;
  search?: boolean;
  backHref?: Routes;
  navigateState?: object;
}

const MobileHeader = ({ title, actions, search = false, backHref, navigateState }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBack = () => {
    dispatch(searchActions.setShowRecentSearches(true));
    // if-else is needed because param override types are causing a ts error otherwise
    if (backHref) {
      navigate(backHref, { state: navigateState });
    } else {
      navigate(-1);
    }
  };

  return (
    <HideOnScroll>
      <HeaderContainer sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Box flex={search ? 0 : 1}>
          <IconButton
            color="default"
            sx={{ ml: -1 }}
            onClick={handleBack}
            aria-label={intl.formatMessage({ id: 'core_general_previous' })}
          >
            <Icon name="IcoArrowBack" />
          </IconButton>
        </Box>

        {title && (
          <Typography
            textAlign="center"
            flex={3}
            variant="h6"
            // ellipsis after 2 lines
            textOverflow="ellipsis"
            overflow="hidden"
            display="-webkit-box"
            sx={{
              ...lineClamp(1),
            }}
          >
            {title}
          </Typography>
        )}

        <Box flex={1} display="flex" justifyContent="flex-end" alignItems="center" gap={2}>
          {actions}
        </Box>
      </HeaderContainer>
    </HideOnScroll>
  );
};

export default MobileHeader;
