import { alpha, Box, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import config from 'config';

import Icon from 'components/@icons/Icon';

import { useTenantHasUpdates } from './TenantUpdateProvider';

const TenantUpdateSidebar = () => {
  const { hasUpdates, reload } = useTenantHasUpdates();

  if (!hasUpdates) return null;

  return (
    <Box
      display="flex"
      gap={1}
      px={2}
      p={1.5}
      borderRadius={3}
      justifyContent="space-between"
      alignItems="center"
      sx={(theme) => ({
        maxWidth: `calc(${theme.mixins.sideBar.width} - 32px)`,
        backgroundColor: alpha(theme.palette.brand.navigation.main.invert, 0.1),
        cursor: 'pointer',
        '&:hover': { backgroundColor: alpha(theme.palette.brand.navigation.main.invert, 0.2) },
        '&:active': { backgroundColor: alpha(theme.palette.brand.navigation.main.invert, 0.3) },
        transition: theme.transitions.create(['background-color']),
      })}
      onClick={reload}
    >
      <Stack gap={0.5} sx={{ wordBreak: 'break-word' }}>
        <Typography
          variant="overline"
          fontWeight={700}
          sx={(theme) => ({ color: theme.palette.brand.navigation.main.invert })}
        >
          <FormattedMessage
            id="core_update_available_alternative"
            values={{ app_name: config.NAME }}
          />
        </Typography>

        <Typography
          variant="caption"
          sx={(theme) => ({ color: theme.palette.brand.navigation.main.invert })}
        >
          <FormattedMessage id="core_update_refresh" />
        </Typography>
      </Stack>
      <Icon
        name="IcoRefresh"
        className="rotate-icon"
        sx={(theme) => ({
          color: theme.palette.brand.navigation.main.invert,
          '&.rotate-icon': {
            WebkitAnimation: 'rotate 2s ease-in-out infinite',
          },
        })}
      />
    </Box>
  );
};

export default TenantUpdateSidebar;
