import { TabPanelProps, useTabContext } from '@mui/lab';
import { Box } from '@mui/material';
import { forwardRef } from 'react';

// We create our own TabPanel that keeps the children mounted in the DOM, MUI is working on getting this supported: https://github.com/mui/material-ui/issues/21250
// Why? Working with React-hook-form we want all the fields to be mounted in the DOM so that validation works as expected.
const TabPanel = forwardRef<HTMLDivElement, TabPanelProps>((props, ref) => {
  const { children, value: id, ...other } = props;

  const context = useTabContext();

  if (context === null) {
    throw new TypeError('No TabContext provided');
  }
  const tabId = context.value;

  return (
    <Box
      aria-labelledby={tabId}
      id={id}
      ref={ref}
      role="tabpanel"
      sx={{
        visibility: id === tabId ? 'visible' : 'hidden',
        height: id === tabId ? 'auto' : 0,
        position: id === tabId ? 'static' : 'absolute',
        top: id === tabId ? undefined : 0,
      }}
      {...other}
    >
      {children}
    </Box>
  );
});

TabPanel.displayName = 'TabPanel';

export default TabPanel;
