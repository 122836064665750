export const getB2cLocale = (locale: string) => {
  let b2cLocale = locale;
  switch (b2cLocale) {
    case 'pt':
      b2cLocale = 'pt-pt';
      break;
    case 'zh':
      b2cLocale = 'zh-hans';
      break;
  }

  return b2cLocale;
};
