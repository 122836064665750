import { Collection, Href } from 'types/api.types';
import {
  DocumentFavouriteParams,
  DocumentItem,
  DocumentLibraryParams,
} from 'types/documents.types';

import RestApiService from '.';

export default {
  getDocument(this: RestApiService, nodeId: string) {
    return this.api.get<DocumentItem>(`/api/document-library/node/${nodeId}`);
  },
  getDocumentLibrary(this: RestApiService, userId: string, params?: DocumentLibraryParams) {
    return this.api.get<Collection<Array<DocumentItem>>>(
      `/api/users/${userId}/document-library/browse`,
      { params },
    );
  },
  getDocumentLibraryFolder(this: RestApiService, folderId: string, params?: DocumentLibraryParams) {
    return this.api.get<Collection<Array<DocumentItem>>>(
      `/api/document-library/browse/${folderId}`,
      {
        params,
      },
    );
  },
  getDocumentLibraryFavourites(
    this: RestApiService,
    userId: string,
    params?: DocumentFavouriteParams,
  ) {
    return this.api.get<Collection<Array<Href>>>(
      `/api/users/${userId}/document-library/favourite-nodes`,
      { params },
    );
  },
  getDocumentLibraryFavouriteIds(this: RestApiService, userId: string) {
    return this.api.get<Collection<Array<string>>>(
      `/api/users/${userId}/document-library/favourite-nodes-ids`,
    );
  },
  createDocumentLibraryFavourite(this: RestApiService, userId: string, node: string) {
    return this.api.post<void>(`/api/users/${userId}/document-library/favourite-nodes`, { node });
  },
  deleteDocumentLibraryFavourite(this: RestApiService, userId: string, nodeId: string) {
    return this.api.delete<void>(`/api/users/${userId}/document-library/favourite-nodes/${nodeId}`);
  },
};
