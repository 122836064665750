import { alpha, Box, Button, Chip, Skeleton, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';

import { QueryKeys, useCreateUserTopic, useCurrentUser, useDeleteUserTopic } from 'queries';

import Icon from 'components/@icons/Icon';

import { useSubscriptionFormTopics } from './hooks';

const NewsSubscriptionsForm = () => {
  const { userId } = useCurrentUser();
  const queryClient = useQueryClient();
  const { deleteUserTopic } = useDeleteUserTopic();
  const { createUserTopic } = useCreateUserTopic();
  const { isLoading, userTopicsHrefs, fetchedUserTopics, mandatoryTopics, otherTopics } =
    useSubscriptionFormTopics();

  const isSelected = (topic: string) => {
    return userTopicsHrefs?.some((item) => item.href === topic);
  };

  const invalidateTopics = () => {
    queryClient.invalidateQueries({ queryKey: QueryKeys.topics.allUserTopics(userId) });
  };

  const handleClick = (topic: string, topicId: number) => {
    if (isSelected(topic)) {
      deleteUserTopic({ userId, topicId, href: topic }, { onSettled: invalidateTopics });
    } else {
      createUserTopic({ userId, topicId, href: topic }, { onSettled: invalidateTopics });
    }
  };

  const handleSelectAll = () => {
    otherTopics.forEach((topic) => {
      createUserTopic(
        { userId, topicId: topic.id, href: topic.href },
        { onSettled: invalidateTopics },
      );
    });
  };

  const handleSelectNone = () => {
    fetchedUserTopics.forEach((topic) => {
      if (topic.canUnsubscribe) {
        deleteUserTopic(
          { userId, topicId: topic.id, href: topic.href },
          { onSettled: invalidateTopics },
        );
      }
    });
  };

  return (
    <>
      {(!!mandatoryTopics.length || isLoading) && (
        <>
          <Typography variant="overline" fontWeight="bold" mb={1}>
            <FormattedMessage id="core_settings_topics_mandatory" />
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={1}>
            {isLoading
              ? Array.from({ length: 3 }, (_, i) => (
                  <Skeleton key={i} variant="rounded" sx={{ borderRadius: 4, height: 32 }}>
                    <Chip sx={{ width: 110 }} />
                  </Skeleton>
                ))
              : mandatoryTopics?.map((topic) => (
                  <Chip
                    key={topic.id}
                    clickable
                    size="medium"
                    onClick={() => handleClick(topic.href, topic.id)}
                    label={topic.name}
                    sx={(theme) => ({
                      '&.Mui-disabled': {
                        color: theme.palette.brand.topicChip.selection.invert,
                        backgroundColor: theme.palette.brand.topicChip.selection[100],
                      },
                    })}
                    disabled
                  />
                ))}
          </Box>
        </>
      )}

      {(!!otherTopics.length || isLoading) && (
        <>
          <Typography variant="overline" fontWeight="bold" mt={3} mb={1}>
            <FormattedMessage id="core_settings_topics_optional" />
          </Typography>
          <Box gap={2} display="flex" mb={1}>
            <Button variant="text" size="small" onClick={isLoading ? undefined : handleSelectAll}>
              <FormattedMessage id="core_general_select_all" />
            </Button>
            <Button variant="text" size="small" onClick={isLoading ? undefined : handleSelectNone}>
              <FormattedMessage id="core_general_select_none" />
            </Button>
          </Box>
          <Box display="flex" flexWrap="wrap" gap={1}>
            {isLoading
              ? Array.from({ length: 9 }, (_, i) => (
                  <Skeleton key={i} variant="rounded" sx={{ borderRadius: 4, height: 32 }}>
                    <Chip sx={{ width: 110 }} />
                  </Skeleton>
                ))
              : otherTopics?.map((topic) => {
                  const isTopicSelected = isSelected(topic.href);
                  return (
                    <Chip
                      key={topic.id}
                      clickable
                      size="medium"
                      onClick={() => handleClick(topic.href, topic.id)}
                      label={
                        <Box display="flex" alignItems="center" gap={0.5}>
                          {topic.name} {isTopicSelected && <Icon name="IcoClose" fontSize={16} />}
                        </Box>
                      }
                      sx={(theme) => ({
                        color: isTopicSelected
                          ? theme.palette.brand.topicChip.selection.invert
                          : theme.palette.brand.topicChip.selection[100],
                        backgroundColor: isTopicSelected
                          ? theme.palette.brand.topicChip.selection[100]
                          : 'transparent',
                        border: `1px solid ${theme.palette.brand.topicChip.selection[100]}`,
                        ':hover': {
                          backgroundColor: {
                            xs: isTopicSelected
                              ? theme.palette.brand.topicChip.selection[100]
                              : theme.palette.brand.topicChip.selection.invert,
                            md: isTopicSelected
                              ? alpha(theme.palette.brand.topicChip.selection[100], 0.9)
                              : alpha(theme.palette.brand.topicChip.selection[100], 0.1),
                          },
                          color: {
                            xs: isTopicSelected
                              ? theme.palette.brand.topicChip.selection.invert
                              : theme.palette.brand.topicChip.selection[100],
                          },
                        },
                      })}
                    />
                  );
                })}
          </Box>
        </>
      )}
    </>
  );
};

export default NewsSubscriptionsForm;
