import { isNative } from 'utils/capacitor.utils';
import { useIsModuleEnabled } from 'utils/module.utils';

import { useTenantConfig } from 'queries';

import { Modules } from 'components/@navigations/config';

// Universal hook to determine if chat module is enabled
const useChatModuleEnabled = () => {
  const { data: tenant } = useTenantConfig();
  const isModuleChatEnabled = useIsModuleEnabled(Modules.Chat);
  // INFO: Determine if chat is enabled based on platform and tenant configuration
  // We have two protection layers modules and retool tenant configuration
  const isChatEnabled = Boolean(
    isModuleChatEnabled &&
      (isNative
        ? tenant?.configuration.features.chat_mobile
        : tenant?.configuration.features.chat_web),
  );
  return {
    isChatEnabled,
  };
};

export default useChatModuleEnabled;
