import { Components, Theme } from '@mui/material';

export const MuiDataGrid: Components<Theme>['MuiDataGrid'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      border: 'none',
      marginTop: theme.spacing(2),
      [theme.breakpoints.down('lg')]: {
        marginTop: theme.spacing(3),
      },
      '& .MuiSelect-select': {
        padding: `0px 24px 0px 0px !important`,
      },
      '& .MuiDataGrid-cell:hover': {
        cursor: 'pointer',
      },
      '& .MuiDataGrid-cell': {
        color: theme.palette.neutral01[700],
      },
      '& .MuiDataGrid-cell, .custom-cell': {
        transition: theme.transitions.create(['color']),
      },
      '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
      '& .MuiDataGrid-row:hover': {
        transition: theme.transitions.create('background-color'),
        backgroundColor: theme.palette.brand.main[10],
      },
      '& .MuiDataGrid-row, .custom-cell': {
        transition: theme.transitions.create('color'),
        '&:hover': {
          '& .MuiDataGrid-cell, .custom-cell': {
            color: theme.palette.brand.main[100],
          },
        },
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 600,
      },
      '& .MuiDataGrid-withBorderColor': {
        borderColor: theme.palette.neutral01[400],
      },
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
      '& .MuiTablePagination-actions': {
        '& .Mui-disabled': {
          backgroundColor: 'transparent',
          color: theme.palette.neutral01[500],
        },
      },
      '& .MuiDataGrid-sortIcon': {
        height: 16,
        width: 16,
      },
      '& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon': {
        color: theme.palette.neutral01[800],
      },
    }),
  },
};
