import { Components, Theme } from '@mui/material';

export const MuiSlider: Components<Theme>['MuiSlider'] = {
  styleOverrides: {
    thumb: {
      '::before': {
        boxShadow: 'none',
      },
    },
  },
};
