import { Device } from '@capacitor/device';
import { useEffect, useState } from 'react';

import { isIos } from 'utils/capacitor.utils';

import { useSafeArea } from 'hooks';

export interface DeviceInfo {
  bottom: number;
  top: number;
  isAndroidBelowOrEqualTo11: boolean;
}

export const initialDeviceInfo: DeviceInfo = {
  bottom: 0,
  top: 0,
  isAndroidBelowOrEqualTo11: false,
};

/**
 * TODO(JS): Instead of using state for this, we should rather use util functions or util hook
 * To determine this at runtime, also we should take a look at https://github.com/capacitor-community/safe-area
 */
const useCapacitorDeviceInfo = () => {
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>(initialDeviceInfo);
  const { bottom, top } = useSafeArea();

  const getAndroidVersion = async () => {
    const info = await Device.getInfo();

    const isAndroidBelowOrEqualTo11 = info.osVersion.split('.')[0] <= '11';

    return isAndroidBelowOrEqualTo11;
  };

  useEffect(() => {
    const getDeviceInfo = async () => {
      const isAndroidBelowOrEqualTo11 = await getAndroidVersion();
      setDeviceInfo({
        bottom: isIos ? bottom / 2 : bottom,
        top: isIos ? top / 2 : top,
        isAndroidBelowOrEqualTo11,
      });
    };

    getDeviceInfo();
  }, [bottom, top]);

  return deviceInfo;
};

export default useCapacitorDeviceInfo;
