import { createRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import { Modules } from 'components/@navigations/config';

export default createRoute({
  path: `${Routes.Tasks}/*`,
  lazy: () => import('./Tasks'),
  moduleId: Modules.Tasks,
  containerSx: ({
    enableZoomScaling,
    zoomFactor,
  }: {
    enableZoomScaling: boolean;
    zoomFactor: number;
    sideBarWidth: number;
  }) => ({
    margin: 0,
    padding: '0!important',
    maxWidth: 'initial !important',
    display: 'flex',
    justifyContent: 'center',
    // This ensures the zoom scale weirdness in ZoomscaleContainer.tsx does not scale down the height of the flex container
    height: enableZoomScaling ? `calc(100vh / ${zoomFactor})` : '100vh',
    // Flex shrink is causing the width calculation on zoomscalecontainer to not work properly
    flexShrink: 0,
    transformOrigin: 'left top',
  }),
});
