import { isEqual } from 'lodash';
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import config from 'config';

import { isNative } from 'utils/capacitor.utils';

import { useTenantConfig, useTenantConfigInterval } from 'queries';

interface ContextProps {
  hasUpdates: boolean;
  reload: () => void;
}

const TenantUpdateContext = createContext<ContextProps>({
  hasUpdates: false,
  reload: () => undefined,
});

export const useTenantHasUpdates = () => {
  return useContext(TenantUpdateContext);
};

interface Props {
  children: ReactNode;
}

const TenantUpdateProvider = ({ children }: Props) => {
  const [hasUpdates, setHasUpdates] = useState(false);
  const { data: initialTenant } = useTenantConfig();

  const { data: tenant } = useTenantConfigInterval({
    // Disabled for now
    refetchInterval: hasUpdates || isNative ? false : false,
    initialData: initialTenant,
  });

  useEffect(() => {
    if (!config.TENANT_CONFIG || hasUpdates || !tenant) return;

    if (!isEqual(tenant, config.TENANT_CONFIG)) {
      setHasUpdates(true);
    }
  }, [hasUpdates, tenant]);

  const contextValue = useMemo(
    () => ({ hasUpdates, reload: window.location.reload }),
    [hasUpdates],
  );

  return (
    <TenantUpdateContext.Provider value={contextValue}>{children}</TenantUpdateContext.Provider>
  );
};

export default TenantUpdateProvider;
