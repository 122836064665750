import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

type Props = {
  check?: boolean;
  callback: EffectCallback;
};

// In dev mode with strict mode on, react runs useEffect hooks twice
// For a lot of cases this doesn't really matter but for handeling redirects
// its important that they are only run once or you can never test it locally
// This is really a bit hacky but we cannot make this idempotent
export const useOnMountUnsafe = (
  { check = true, callback }: Props,
  dependencies: DependencyList,
) => {
  const initialized = useRef(false);

  useEffect(() => {
    if (check) {
      if (!initialized.current) {
        initialized.current = true;
        callback();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, check]);
};
