import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import localStorage from 'redux-persist/es/storage';

import config from 'config';

type SearchState = {
  recentSearches: Array<string>;
  showRecentSearches: boolean;
};

export const searchPersistConfig = {
  key: `${config.LOCAL_STORAGE_ID}.search`,
  storage: localStorage,
};

const initialState: SearchState = {
  recentSearches: [],
  showRecentSearches: true,
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    addRecentSearch: (state, { payload }: PayloadAction<string>) => {
      if (state.recentSearches.length >= 5) {
        const uniqueSearches = Array.from(new Set([payload, ...state.recentSearches]));
        uniqueSearches.pop();
        state.recentSearches = uniqueSearches;
      } else {
        const uniqueSearches = Array.from(new Set([payload, ...state.recentSearches]));
        state.recentSearches = uniqueSearches;
      }
    },
    setShowRecentSearches: (state, { payload }: PayloadAction<boolean>) => {
      state.showRecentSearches = payload;
    },
    resetRecentSearches: (state) => {
      state.recentSearches = [];
    },
  },
});

export const { actions: searchActions } = searchSlice;

export default searchSlice.reducer;
