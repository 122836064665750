import { createRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import { Modules } from 'components/@navigations/config';

export default createRoute({
  path: Routes.Bookmarks,
  lazy: () => import('./Bookmarks'),
  moduleId: Modules.Bookmarks,
  isCoreModule: true,
});
