import { ReactNode, useEffect, useState } from 'react';

import config from 'config';

import { captureException } from 'utils/sentry.utils';

import { useTenantConfig } from 'queries';
import { initServices } from 'services';

import { Splash } from 'components/@splash';

interface Props {
  children: ReactNode;
}

const TenantGate = ({ children }: Props) => {
  const { data: tenant, error } = useTenantConfig();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!!tenant) {
      config.setTenantConfig(tenant);
      initServices();
      setIsLoading(false);
    } else if (error) {
      captureException(error);
      window.location.href = 'https://spencer.co';
    }
  }, [tenant, error]);

  if (isLoading || error) return <Splash isLoading />;

  return <>{children}</>;
};

export default TenantGate;
