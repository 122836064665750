import { Box, Drawer, MenuList, Stack } from '@mui/material';
import { useIntl } from 'react-intl';

import { hideScrollbar } from 'utils/styles.utils';

import { useTenantConfig } from 'queries';
import { TenantUpdateSidebar } from 'services/tenant/TenantUpdateProvider';

import { TenantLogo } from 'components/@common';
import Icon from 'components/@icons';
import { BottomNav } from 'components/@navigations/BottomNav';
import { useNavItems } from 'components/@navigations/hooks';

import NavigationImage from './NavigationImage';
import NavItem from './NavItem';

const DrawerNavigation = () => {
  const intl = useIntl();
  const navItems = useNavItems();

  const { data: tenant } = useTenantConfig();

  const zoomFactor = tenant?.configuration.zoom;

  return (
    <div className="no-print" id="drawer-navigation">
      <Drawer
        variant="permanent"
        PaperProps={{
          sx: (theme) => ({
            pb: 3,
            width: theme.mixins.sideBar.width,
            position: 'fixed',
            backgroundColor: theme.palette.brand.navigation.main[100],
            borderRight: `1px solid ${theme.palette.neutral01[400]}`,
            transform: zoomFactor ? `scale(${zoomFactor})` : 'none',
            transformOrigin: zoomFactor ? 'left top' : 'none',
            height: zoomFactor ? `${100 / Number(zoomFactor)}%` : '100%',
          }),
        }}
      >
        <Box pt={2.5} px={2}>
          <TenantLogo />
        </Box>

        <Stack flex="1" overflow="hidden" position="relative" mt={2}>
          <Box overflow="scroll" sx={{ ...hideScrollbar }}>
            <Box
              sx={(theme) => ({
                position: 'absolute',
                top: 0,
                left: 0,
                height: '30px',
                width: '100%',
                zIndex: 1,
                background: `linear-gradient(180deg, ${theme.palette.brand.navigation.main[100]} 0%, rgba(0,0,0,0) 73%)`,
              })}
            />
            <Box
              sx={(theme) => ({
                position: 'absolute',
                bottom: 0,
                left: 0,
                height: '30px',
                width: '100%',
                zIndex: 1,
                background: `linear-gradient(0deg, ${theme.palette.brand.navigation.main[100]} 0%, rgba(0,0,0,0) 73%)`,
              })}
            />
            <MenuList sx={{ px: 2, my: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
              {navItems.map((item, index) =>
                'Component' in item ? (
                  <item.Component key={index} />
                ) : (
                  <NavItem
                    key={index}
                    to={item.to}
                    target={item.target}
                    label={item.fetched ? item.label : intl.formatMessage({ id: item.label })}
                    startIcon={
                      !item.fetched && item.startIcon ? (
                        <Icon name={item.startIcon} />
                      ) : item.startImage ? (
                        <NavigationImage startImage={item.startImage} />
                      ) : undefined
                    }
                  />
                ),
              )}
            </MenuList>
          </Box>
        </Stack>
        <Stack px={2} pt={1} gap={1} mt="auto">
          <TenantUpdateSidebar />
          <BottomNav />
        </Stack>
      </Drawer>
      <Box
        sx={(theme) => ({
          width: zoomFactor
            ? `calc(${theme.mixins.sideBar.width} * ${zoomFactor})`
            : `${theme.mixins.sideBar.width}`,
        })}
      />
    </div>
  );
};

export default DrawerNavigation;
