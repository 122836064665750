import { useHref } from 'queries';
import { Href } from 'types/api.types';
import { StorageMedia } from 'types/media.types';

import { Image } from 'components/@common';

interface Props {
  startImage: Href;
}

const NavigationImage = ({ startImage }: Props) => {
  const { data: media } = useHref<StorageMedia>(startImage.href);

  return <Image src={media?.signedUrl} width={24} height={24} sx={{ objectFit: 'cover' }} />;
};

export default NavigationImage;
