import { Box } from '@mui/material';

import TenantUpdateFooter from 'services/tenant/TenantUpdateProvider/TenantUpdateFooter';

type Props = {
  children: React.ReactNode;
};

const FooterContainer = ({ children }: Props) => {
  return (
    <>
      <Box
        position="fixed"
        width="100%"
        bottom={0}
        sx={(theme) => ({ zIndex: theme.mixins.zIndex.footer })}
        className="no-print"
      >
        <TenantUpdateFooter />
        <Box
          borderTop={(theme) => `1px solid ${theme.palette.neutral01[400]}`}
          bgcolor={(theme) => theme.palette.neutral01[100]}
          display="flex"
          alignItems="center"
        >
          {children}
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: `calc(env(safe-area-inset-bottom) + ${theme.mixins.footer.height})`,
        })}
      />
    </>
  );
};

export default FooterContainer;
