import { ReactionKind } from 'types/news.types';

export type ReactionKindType = Exclude<ReactionKind, 'comment'>;

type ReactionLookup = Record<
  ReactionKindType,
  {
    icon:
      | 'IcoReactionLove'
      | 'IcoReactionLike'
      | 'IcoReactionCurious'
      | 'IcoReactionInspired'
      | 'IcoReactionCelebrate';
    color: string;
    tooltip: string;
  }
>;

export const ReactionLookup: ReactionLookup = {
  [ReactionKind.Love]: {
    icon: 'IcoReactionLove',
    color: 'reaction.love',
    tooltip: 'module_companynews_reactions_love',
  },
  [ReactionKind.Like]: {
    icon: 'IcoReactionLike',
    color: 'reaction.like',
    tooltip: 'module_companynews_reactions_like',
  },
  [ReactionKind.Curious]: {
    icon: 'IcoReactionCurious',
    color: 'reaction.curious',
    tooltip: 'module_companynews_reactions_curious',
  },
  [ReactionKind.Inspired]: {
    icon: 'IcoReactionInspired',
    color: 'reaction.inspired',
    tooltip: 'module_companynews_reactions_inspired',
  },
  [ReactionKind.Celebrate]: {
    icon: 'IcoReactionCelebrate',
    color: 'reaction.celebrate',
    tooltip: 'module_companynews_reactions_celebrate',
  },
};
