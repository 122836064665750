import { NonIndexRouteObject, RouteObject } from 'react-router-dom';

import { useEnabledModules, useMe, useTenantConfig } from 'queries';
import useChatModuleEnabled from 'services/chat/hooks/useChatModuleEnabled';

import { Modules } from 'components/@navigations/config';

export const useIsModuleEnabled = (moduleId: Modules) => {
  const { data: enabledModules } = useEnabledModules();
  const { data: meData } = useMe();

  return enabledModules?.includes(moduleId) && !meData?.restrictedModules?.includes(moduleId);
};

export const useEnabledRouteModules = (children: Array<RouteObject>) => {
  const { data: enabledModules, isLoading: isLoadingEnabledModules } = useEnabledModules();
  const { data: meData, isLoading: isLoadingMe } = useMe();
  const { isChatEnabled } = useChatModuleEnabled();
  const { data: tenantConfig } = useTenantConfig();

  const enabledRoutes = children.filter((route) => {
    const moduleId = route?.moduleId;

    // Step 1: Check the chat module in Tenant Settings
    if (route.moduleId === Modules.Chat) {
      return isChatEnabled;
    }

    // Step 2: Always allow core modules
    if (route.isCoreModule) {
      return true;
    }

    // Step 3: Check module enablement, user restrictions, and tenant configuration
    if (moduleId) {
      const isModuleEnabled = enabledModules?.includes(moduleId);
      const isNotRestricted = !meData?.restrictedModules?.includes(moduleId);

      // Step 4: Check tenant configuration if the module is enabled
      const featureKey = Object.keys(tenantConfig?.configuration?.features || {}).find(
        (key) => key.toLowerCase() === moduleId.toLowerCase(),
      );
      const isFeatureEnabled = featureKey
        ? (tenantConfig?.configuration?.features as Record<string, boolean>)?.[featureKey] !== false
        : true;

      return isModuleEnabled && isNotRestricted && isFeatureEnabled;
    }

    // Step 5: Allow routes without moduleId
    return true;
  });

  const enabledRoutesWithChildren = enabledRoutes.map((route) => {
    return {
      ...route,
      children: route.children?.filter((child) => {
        const moduleId = child?.moduleId;
        if (moduleId) {
          const isModuleEnabled = enabledModules?.includes(moduleId);
          const isNotRestricted = !meData?.restrictedModules?.includes(moduleId);

          // Check tenant configuration for child routes
          const featureKey = Object.keys(tenantConfig?.configuration?.features || {}).find(
            (key) => key.toLowerCase() === moduleId.toLowerCase(),
          );
          const isFeatureEnabled = featureKey
            ? (tenantConfig?.configuration?.features as Record<string, boolean>)?.[featureKey] !==
              false
            : true;

          return isModuleEnabled && isNotRestricted && isFeatureEnabled;
        }
        return true;
      }),
    } as NonIndexRouteObject & { children: Array<RouteObject> };
  });

  return {
    enabledRouteModules: enabledRoutesWithChildren,
    isLoading: isLoadingEnabledModules || isLoadingMe,
  };
};
