import { Components, Theme } from '@mui/material';

export const MuiBreadcrumbs: Components<Theme>['MuiBreadcrumbs'] = {
  styleOverrides: {
    li: {
      display: 'flex',
      alignItems: 'center',
    },
  },
};
