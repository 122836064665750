import { Box, SxProps, Theme } from '@mui/material';
import { forwardRef } from 'react';

import { isAndroid, isIos } from 'utils/capacitor.utils';

import { useMedia } from 'hooks';
import { useTenantConfig } from 'queries';
import { useDeviceInfo } from 'services/capacitor/CapacitorProvider';

type Props = {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

const HeaderContainer = forwardRef<HTMLDivElement, Props>(({ children, sx }, ref) => {
  const { data: tenant } = useTenantConfig();
  const { isDesktop } = useMedia();
  const zoomFactor = tenant?.configuration.zoom;
  const enableZoom = zoomFactor && isDesktop;

  const { top } = useDeviceInfo();

  return (
    <Box
      id="header-container"
      pb={isIos ? 1.5 : 0}
      pr={2}
      display="flex"
      alignItems="center"
      flexWrap="nowrap"
      justifyContent={{ xs: 'space-between', lg: 'center' }}
      width="100%"
      borderBottom={(theme) => ({ xs: `1px solid ${theme.palette.neutral01[400]}`, lg: 'none' })}
      bgcolor="neutral01.100"
      pt={
        enableZoom
          ? `calc(12px * ${zoomFactor} + env(safe-area-inset-top))`
          : 'calc(12px  + env(safe-area-inset-top))'
      }
      position="sticky"
      top={0}
      sx={[
        (theme) => ({
          pl: {
            lg: enableZoom
              ? `calc(${theme.mixins.sideBar.width} * ${zoomFactor}) !important`
              : theme.mixins.sideBar.width,
            xs: 2,
          },
          height: enableZoom
            ? `calc(${theme.mixins.header.height} * ${zoomFactor} + env(safe-area-inset-top))`
            : `calc(${theme.mixins.header.height} ${isIos ? '+ env(safe-area-inset-top)' : ''} ${isAndroid ? `+ ${top}px` : ''})`,
          zIndex: theme.mixins.zIndex.header,
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      ref={ref}
      className="no-print"
    >
      {children}
    </Box>
  );
});

HeaderContainer.displayName = 'HeaderContainer';

export default HeaderContainer;
