import { Collection } from 'types/api.types';
import {
  GetApplicationParams,
  NotificationItem,
  PutApplicationParams,
  PutNotificationParams,
} from 'types/notification.types';
import { User } from 'types/user.types';

import RestApiService from '.';

export default {
  getSubscriptions(this: RestApiService, userId: User['spencerUuid'], applicationName: string) {
    return this.api.get<Collection<Array<NotificationItem>>>(
      `/api/notifications/users/${userId}/applications/${applicationName}/subscriptions`,
    );
  },
  putSubscriptions(
    this: RestApiService,
    { userId, applicationName, topicName, isSubscribed }: PutNotificationParams,
  ) {
    return this.api.put(
      `/api/notifications/users/${userId}/applications/${applicationName}/subscriptions/${topicName}`,
      {
        isSubscribed,
      },
    );
  },
  getApplication(this: RestApiService, { applicationName, endpointArn }: GetApplicationParams) {
    return this.api.get(
      `/api/notifications/applications/${applicationName}/endpoints/${endpointArn}`,
    );
  },
  createApplicationEndpoint(
    this: RestApiService,
    applicationName: string,
    arn: string,
    spencerUuid: string,
    language: string,
  ) {
    return this.api.post(`/api/notifications/applications/${applicationName}/endpoints`, {
      arn,
      spencerUuid,
      language,
    });
  },
  putApplication(
    this: RestApiService,
    { applicationName, endpointArn, spencerUuid, language }: PutApplicationParams,
  ) {
    return this.api.put(
      `/api/notifications/applications/${applicationName}/endpoints/${window.btoa(endpointArn)}`,
      {
        arn: endpointArn,
        spencerUuid,
        language,
      },
    );
  },
  getNotificationTopics(this: RestApiService, applicationName: string) {
    return this.api.get(`/api/notifications/applications/${applicationName}/topics`);
  },
};
