import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { useSelectedLocale } from 'services/i18n';
import { useNotifications } from 'services/snackbar';
import { Collection, Href } from 'types/api.types';

interface Params {
  userId: string;
  topicId: number;
  href: string;
}

const deleteUserTopic = async ({ userId, topicId }: Params) =>
  apiService.deleteUserTopic(userId, topicId);

export const useDeleteUserTopic = () => {
  const { locale } = useSelectedLocale();
  const { error } = useNotifications();
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: deleteUserTopic,
    onMutate: async (params) => {
      await queryClient.cancelQueries({ queryKey: QueryKeys.topics.user(params.userId, locale) });
      const previousTopics = queryClient.getQueryData<InfiniteData<Collection<Array<Href>>>>(
        QueryKeys.topics.user(params.userId, locale),
      );

      if (!previousTopics) return;

      const newDataArrays = previousTopics.pages.map((page) => {
        return {
          data: page.data?.filter((item) => item.href !== params.href),
        };
      });

      const newTopics: InfiniteData<Collection<Array<Href>>> = {
        ...previousTopics,
        pages: newDataArrays,
      };

      queryClient.setQueryData(QueryKeys.topics.user(params.userId, locale), newTopics);

      return { previousTopics };
    },
    onError: (_error, { userId }, context) => {
      error({
        title: intl.formatMessage({ id: 'core_general_alert_error_title' }),
        message: intl.formatMessage({ id: 'core_general_failed' }),
      });
      queryClient.setQueryData(QueryKeys.topics.allUserTopics(userId), context?.previousTopics);
    },
  });

  return { deleteUserTopic: mutate, isLoading: isPending };
};
