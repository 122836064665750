import { Href } from './api.types';

export enum LinkType {
  WebLink = 'web_link',
  MobileApplication = 'mobile_application',
  DesktopApplication = 'desktop_application',
  Form = 'form',
}

export type LinksResponse = {
  data: Array<Href>;
};

export type Link = {
  id: number;
  contactEmail: string;
  image: Href;
  name: string;
  openExternal: boolean;
  type: LinkType;
  webUrl: string;
  formPublication?: Href;
};

export type LinkParams = {
  featured?: boolean;
  p?: number;
  type: LinkType;
};
