export const getRatio = (file: File): Promise<number> => {
  const img = new Image();
  img.src = window.URL.createObjectURL(file);

  return new Promise((resolve) => {
    img.addEventListener('load', () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      resolve(width / height);
    });
  });
};
