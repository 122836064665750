import { HTMLAttributeAnchorTarget, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import { hapticsImpact } from 'utils/capacitor.utils';

import MenuItemComponent from 'components/@navigations/DrawerNavigation/components/MenuItem';

interface Props {
  label: string;
  to: string;
  target?: HTMLAttributeAnchorTarget;
  startIcon?: React.ReactNode;
  action?: (isActive: boolean) => ReactNode;
  removeBorderRadius?: boolean;
}

const NavItem = ({ label, startIcon, to, action, target = '_self', removeBorderRadius }: Props) => {
  return (
    <NavLink
      to={to}
      title={label}
      target={target}
      onClick={async () => await hapticsImpact('light')}
    >
      {({ isActive }) => (
        <MenuItemComponent
          label={label}
          startIcon={startIcon}
          action={action ? action(isActive) : undefined}
          isActive={isActive}
          removeBorderRadius={removeBorderRadius}
        />
      )}
    </NavLink>
  );
};

export default NavItem;
