import { Box, BoxProps } from '@mui/material';
import { useState } from 'react';

import { captureException } from 'utils/sentry.utils';

// We don't want the full key, just the last 25 characters to avoid storing too much data
const KEY_CHARS = 25;

const loadImageLoadedState = (key?: string) => {
  if (!key) return false;
  try {
    return sessionStorage.getItem(key) === 'true';
  } catch {
    return false;
  }
};

const saveImageLoadedState = (key: string) => {
  try {
    sessionStorage.setItem(key, 'true');
  } catch (error) {
    captureException(error, { level: 'warning' });
  }
};

interface Props extends BoxProps {
  src: HTMLImageElement['src'] | undefined;
  alt?: HTMLImageElement['alt'];
}

const Image = ({ src, alt, sx, ...boxProps }: Props) => {
  const key = src ? `loaded-${src.slice(-KEY_CHARS)}` : undefined;
  const [isLoaded, setIsLoaded] = useState(loadImageLoadedState(key));

  const handleLoad = () => {
    if (!isLoaded && key) {
      setIsLoaded(true);
      saveImageLoadedState(key);
    }
  };

  if (!src) return null;

  return (
    <Box
      component="img"
      src={src}
      alt={alt}
      sx={[
        {
          transition: 'opacity 0.5s ease-in-out',
          opacity: isLoaded ? 1 : 0,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      onLoad={handleLoad}
      {...boxProps}
    />
  );
};

export default Image;
