import { useInfiniteNewsTopics, useInfiniteUserTopics, useTopics } from 'queries';

export const useSubscriptionFormTopics = () => {
  const { data: newsTopicsHrefs = [], isLoading: loadingNewsTopicsHrefs } = useInfiniteNewsTopics();
  const { topics: fetchedNewsTopics, isLoading: loadingNewsTopics } = useTopics(newsTopicsHrefs);

  const { data: userTopicsHrefs = [], isLoading: loadingUserTopicsHrefs } = useInfiniteUserTopics();
  const { topics: fetchedUserTopics, isLoading: loadingUserTopics } = useTopics(userTopicsHrefs);

  const isLoading =
    loadingNewsTopicsHrefs || loadingNewsTopics || loadingUserTopicsHrefs || loadingUserTopics;

  const mandatoryTopics = fetchedNewsTopics.filter((topic) => !topic.canUnsubscribe);
  const otherTopics = fetchedNewsTopics.filter((topic) => topic.canUnsubscribe);

  return {
    isLoading,
    mandatoryTopics,
    otherTopics,
    userTopicsHrefs,
    fetchedUserTopics,
  };
};
