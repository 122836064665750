import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { QueryKeys, useCurrentUser } from 'queries';
import {
  defaultGetNextPageParam,
  defaultInitialPageParam,
} from 'queries/helpers/createInfiniteQuery';
import { apiService } from 'services';
import { useSelectedLocale } from 'services/i18n';
import { Pagination } from 'types/api.types';

const fetchNewsTopics = async (userId: string, params: Pagination) => {
  const { data: response } = await apiService.getNewsTopics(userId, params);
  return response;
};

export const useInfiniteNewsTopics = () => {
  const { locale } = useSelectedLocale();
  const { userId } = useCurrentUser();
  const [isPaginationLoading, setIsPaginationloading] = useState(true);

  const {
    fetchNextPage,
    error,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    data,
    isSuccess,
    isLoading,
  } = useInfiniteQuery({
    queryKey: QueryKeys.topics.all(locale, userId),
    initialPageParam: defaultInitialPageParam,
    queryFn: ({ pageParam }) => fetchNewsTopics(userId, { p: Number(pageParam) }),
    getNextPageParam: defaultGetNextPageParam,
  });

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    } else if (!hasNextPage && !isLoading) {
      setIsPaginationloading(false);
    }
  }, [hasNextPage, isLoading, fetchNextPage, data]);

  return {
    data: data?.pages.flatMap((page) => page.data ?? []) ?? [],
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
    isLoading: isPaginationLoading || isLoading,
    hasNextPage,
    isSuccess,
    error,
  };
};
