import { useEffect, useState } from 'react';
import { DefaultGenerics, Event } from 'stream-chat';
import { useCreateChatClient } from 'stream-chat-react';

import { useCurrentUser } from 'queries';
import { useChatUserToken } from 'queries/chat';

const transformUnreadCount = (count: number): string | null => {
  if (count >= 1000) return '1k+';
  if (count >= 100) return '99+';
  if (count > 0) return count.toString();
  return null;
};

// Custom hook to fetch and return the unread messages count
export const useUnreadMessagesCount = () => {
  const [unreadMessagesCount, setUnreadMessagesCount] = useState<string | null>(null);

  // INFO: Provided client doesn't work for both: subscription events and Chat client
  const { data: userTokenData } = useChatUserToken();
  const { user } = useCurrentUser();
  const serviceUUID = user?.serviceUuid;
  const chatClient = useCreateChatClient({
    apiKey: userTokenData?.apiKey || '',
    tokenOrProvider: userTokenData?.token,
    userData: { id: serviceUUID || '' },
  });

  // Determine if there are any unread messages
  const hasUnreadMessages = Boolean(unreadMessagesCount);

  useEffect(() => {
    if (chatClient) {
      // Fetch initial unread messages count
      const fetchUnreadMessagesCount = async () => {
        try {
          const count = await chatClient.getUnreadCount();
          setUnreadMessagesCount(transformUnreadCount(count.total_unread_count));
        } catch (error) {
          console.error('Error fetching unread messages count:', error);
        }
      };

      fetchUnreadMessagesCount();

      // Listen for new unread message events
      const handleNewMessageEvent = (event: Event<DefaultGenerics>) => {
        if (event.total_unread_count !== undefined) {
          setUnreadMessagesCount(transformUnreadCount(event.total_unread_count));
        }
      };

      // Subscribe to new message notifications
      const subscriptionNewMessage = chatClient.on(
        'notification.message_new',
        handleNewMessageEvent,
      );
      const subscriptionUnRead = chatClient.on('notification.mark_unread', handleNewMessageEvent);
      const subscriptionRead = chatClient.on('notification.mark_read', handleNewMessageEvent);

      // Cleanup listener on unmount
      return () => {
        subscriptionNewMessage.unsubscribe();
        subscriptionUnRead.unsubscribe();
        subscriptionRead.unsubscribe();
      };
    }
  }, [chatClient]);

  return { unreadMessagesCount, hasUnreadMessages };
};
