import { Capacitor } from '@capacitor/core';
import { SnackbarProvider as NotistackProvider } from 'notistack';

import { useMedia } from 'hooks/useMedia';

interface Props {
  children: React.ReactNode;
}

const SnackbarProvider = ({ children }: Props) => {
  const { isDesktop } = useMedia();
  const isNative = Capacitor.isNativePlatform();

  return (
    <NotistackProvider
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      maxSnack={1}
      autoHideDuration={5000}
      preventDuplicate
      domRoot={document.getElementById('snackbar-root') ?? undefined}
      style={{
        boxShadow: 'none',
        backgroundColor: 'unset',
        marginBottom: isDesktop
          ? undefined
          : `calc(32px ${isNative ? '+ env(safe-area-inset-bottom)' : ''})`,
        marginRight: -24,
        marginLeft: -8,
        paddingLeft: 0,
      }}
      dense
    >
      {children}
    </NotistackProvider>
  );
};

export default SnackbarProvider;
