import { useQueries, UseQueryOptions } from '@tanstack/react-query';

import { notEmpty } from 'utils/typescript.utils';

import { Duration } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { useSelectedLocale } from 'services/i18n';

type Options<T> = {
  localized?: boolean;
  config?: Omit<UseQueryOptions<T, Error, T, Array<string | undefined>>, 'queryKey' | 'queryFn'>;
};

const fetchHref = async (href: string) => {
  const { data: response } = await apiService.api(href);
  return response;
};

/**
 * Generic query for fetching hrefs in parallel
 */
export const useHrefs = <T>(
  hrefs: Array<string>,
  { config = {}, localized = true }: Options<T> = { localized: true, config: {} },
) => {
  const { locale } = useSelectedLocale();

  const queries = useQueries({
    queries: hrefs?.map((href) => ({
      ...config,
      queryKey: QueryKeys.href(href ?? '', localized ? locale : undefined),
      queryFn: () => fetchHref(href ?? ''),
      staleTime: Duration.FIFTEEN_MIN,
      enabled: !!href,
    })),
    combine: (result) => {
      return {
        isLoading: !!hrefs.length && result.some((item) => item.isLoading),
        isFetching: !!hrefs.length && result.some((item) => item.isFetching),
        data: result?.flatMap((item) => item.data).filter(notEmpty),
      };
    },
  });

  return {
    data: queries.data,
    isLoading: queries.isLoading,
    isFetching: queries.isFetching,
  };
};
