import { useMsal } from '@azure/msal-react';
import { Browser } from '@capacitor/browser';
import { useMutation } from '@tanstack/react-query';

import { isNative } from 'utils/capacitor.utils';

import { localStoragePersister } from 'services/react-query';
import storeConfig from 'store';

export const useLogOut = () => {
  const { instance: msalInstance } = useMsal();

  const { mutateAsync, isPending, isError, isSuccess } = useMutation({
    mutationFn: async () => {
      if (isNative) {
        await msalInstance.logoutRedirect({
          onRedirectNavigate: (url) => {
            Browser.open({ url });
            return false;
          },
        });
      } else {
        await msalInstance.logoutRedirect();
      }
    },
    onMutate: async () => {
      localStorage.clear();
      sessionStorage.clear();

      await storeConfig.persistor.purge();
      await localStoragePersister.removeClient();
    },
  });

  return { logOut: mutateAsync, isLoading: isPending, isError, isSuccess };
};
