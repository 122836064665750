import { Helmet } from 'react-helmet-async';

import config from 'config';

const DefaultSeo = () => {
  return (
    <Helmet
      title={config.NAME}
      link={
        !!config.ASSETS.favicon
          ? [{ rel: 'icon', type: 'image/png', href: config.ASSETS.favicon }]
          : undefined
      }
    />
  );
};

export default DefaultSeo;
