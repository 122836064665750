import {
  PollAnswersResponse,
  PostSurveyParams,
  Survey,
  SurveyAnswerResponse,
} from 'types/survey.types';

import RestApiService from '.';

export default {
  postSurvey(
    this: RestApiService,
    userId: string,
    publicationId: number,
    params: PostSurveyParams,
  ) {
    return this.api.post(`/api/users/${userId}/forms/surveys/${publicationId}/submits`, params);
  },
  getSurvey(this: RestApiService, publicationId: number) {
    return this.api.get<Survey>(`/api/forms/surveys/${publicationId}`);
  },
  getSurveyAnswers(this: RestApiService, publicationId: number, userId: string) {
    return this.api.get<SurveyAnswerResponse>(
      `/api/users/${userId}/forms/surveys/${publicationId}/submits`,
    );
  },
  getPollAnswers(this: RestApiService, publicationId: number) {
    return this.api.get<PollAnswersResponse>(`/api/forms/surveys/${publicationId}/submits`);
  },
  updateSurveyAnswers(
    this: RestApiService,
    userId: string,
    publicationId: number,
    submitId: number,
    params: PostSurveyParams,
  ) {
    return this.api.put(
      `/api/users/${userId}/forms/surveys/${publicationId}/submits/${submitId}`,
      params,
    );
  },
  postForm(this: RestApiService, userId: string, publicationId: number, params: PostSurveyParams) {
    return this.api.post(`/api/users/${userId}/forms/${publicationId}/form-submissions`, params);
  },
};
