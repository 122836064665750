import { SplashScreen } from '@capacitor/splash-screen';
import { ReactNode, useEffect } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { useDispatch } from 'react-redux';

import config from 'config';

import { isNative } from 'utils/capacitor.utils';
import { setExtra } from 'utils/sentry.utils';

import { useInitialiseLocales } from 'queries';
import { useSelectedLocale } from 'services/i18n';
import { intlActions } from 'store/intl/intl.slice';

import { Splash } from 'components/@splash';

interface Props {
  children: ReactNode;
}

// Needs to be before IdentityGate since we have a IdentityRetry.tsx component that needs translations
const IntlProvider = ({ children }: Props) => {
  const { locale } = useSelectedLocale();
  const dispatch = useDispatch();
  const { isLoading, intl, isError: intlError } = useInitialiseLocales();

  useEffect(() => {
    setExtra('selectedLocale', locale);
  }, [locale]);

  useEffect(() => {
    if (!config.LOCALES.available.includes(locale)) {
      dispatch(intlActions.updateLocale(config.LOCALES.default));
    }
  }, [dispatch, locale]);

  if (intlError) {
    throw new Error('Something went wrong while loading the translations');
  }

  const hideSplashscreen = async () => {
    await SplashScreen.hide();
  };

  useEffect(() => {
    if (isNative && !isLoading) hideSplashscreen();
  }, [isLoading]);

  if (isLoading) return <Splash isLoading={isLoading} />;

  return (
    <ReactIntlProvider locale={locale} messages={intl?.[locale]?.messages}>
      {children}
    </ReactIntlProvider>
  );
};

export default IntlProvider;
