import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ChatMenuState = {
  isMenuVisible: boolean;
};

const initialState: ChatMenuState = {
  isMenuVisible: false, // This will be dynamically set based on media query
};

const chatMenuSlice = createSlice({
  name: 'chatMenu',
  initialState,
  reducers: {
    toggleMenu: (state) => {
      state.isMenuVisible = !state.isMenuVisible;
    },
    setMenuVisibility: (state, action: PayloadAction<boolean>) => {
      state.isMenuVisible = action.payload;
    },
  },
});

export const { toggleMenu, setMenuVisibility } = chatMenuSlice.actions;

export default chatMenuSlice.reducer;
