import { Components, Theme } from '@mui/material';

import config from 'config';

import { hideScrollbar } from 'utils/styles.utils';

export const MuiDialog: Components<Theme>['MuiDialog'] = {
  styleOverrides: {
    paper: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius * 4,
      overflowX: 'hidden',
      transform: config?.TENANT_CONFIG?.configuration?.zoom
        ? `scale(${config?.TENANT_CONFIG?.configuration?.zoom})`
        : 'none',
      ...hideScrollbar,
    }),
    paperFullScreen: {
      borderRadius: 0,
    },
  },
};

export const MuiDialogTitle: Components<Theme>['MuiDialogTitle'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      paddingBottom: 0,

      '& + .MuiDialogContent-root': {
        // need !important here because Mui is setting it to 0 with max specificity
        paddingTop: theme.spacing(3) + ' !important',
      },
    }),
  },
};

export const MuiDialogContent: Components<Theme>['MuiDialogContent'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...hideScrollbar,

      paddingBottom: theme.spacing(3),

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3, 2),
        paddingTop: theme.spacing(2),
      },
    }),
  },
};

export const MuiDialogActions: Components<Theme>['MuiDialogActions'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      marginTop: theme.spacing(3),
      padding: 0,

      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
      },
    }),
  },
};
