import { Components, Theme } from '@mui/material';

export const MuiListItemButton: Components<Theme>['MuiListItemButton'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      transition: theme.transitions.create(['color', 'background-color']),
      borderRadius: theme.shape.borderRadius * 5,
      color: theme.palette.neutral01[600],
      '&.Mui-selected': {
        color: theme.palette.brand.main[100],
        backgroundColor: theme.palette.brand.main[10],
      },
      ':hover': {
        color: theme.palette.brand.main[100],
        backgroundColor: theme.palette.brand.main[10],
      },
    }),
  },
};
