import { Components, Theme } from '@mui/material';

export const MuiFormControl: Components<Theme>['MuiFormControl'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      [`
        & label + .MuiInputBase-root > input,
        & label + .MuiInputBase-multiline
      `]: {
        padding: theme.spacing(3, 1.5, 1, 1.5),
      },
    }),
  },
};
