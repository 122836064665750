import { HTMLAttributeAnchorTarget } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import MenuItemComponent from 'components/@navigations/DrawerNavigation/components/MenuItem';

interface Props extends LinkProps {
  label: string;
  to: string;
  target?: HTMLAttributeAnchorTarget;
  startIcon?: React.ReactNode;
  action?: React.ReactNode;
  removeBorderRadius?: boolean;
}

const LinkItem = ({
  label,
  startIcon,
  to,
  action,
  target = '_self',
  removeBorderRadius,
}: Props) => {
  return (
    <Link to={to} title={label} target={target}>
      <MenuItemComponent
        label={label}
        startIcon={startIcon}
        action={action}
        removeBorderRadius={removeBorderRadius}
      />
    </Link>
  );
};

export default LinkItem;
