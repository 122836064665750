import { useSelector } from 'react-redux';

import config from 'config';

import { getLocale } from 'store/intl/intl.selectors';

export const useSelectedLocale = () => {
  const locale = useSelector(getLocale) ?? config.LOCALES.default;

  return {
    locale,
    streamLocale: locale.replace('-', '_'),
    trimmedLocale: locale.slice(0, 2),
  };
};
