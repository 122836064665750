import * as Sentry from '@sentry/capacitor';

import config from 'config';

export const initNativeSentry = () => {
  Sentry.init({
    ...config.NATIVE_SENTRY,
    tracesSampleRate: 0.05,
    dist: config.VERSION,
  });
};
