import type { Collection, Href, SiteParams } from 'types/api.types';
import type { Site } from 'types/sites.types';

import RestApiService from '.';

export default {
  getSites(this: RestApiService, params: SiteParams) {
    return this.api.get<Collection<Array<Href>>>(`/api/sites`, { params });
  },
  getSite(this: RestApiService, siteId: Site['id']) {
    return this.api.get<Site>(`/api/sites/${siteId}`);
  },
  getZones(this: RestApiService, siteId: Site['id']) {
    return this.api.get<Collection<Array<Href>>>(`/api/sites/${siteId}/zones`);
  },
};
