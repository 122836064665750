import { Slide, useScrollTrigger } from '@mui/material';
import { isValidElement, ReactNode } from 'react';

import { isNative } from 'utils/capacitor.utils';

interface Props {
  children: ReactNode;
}

const HideOnScroll = ({ children }: Props) => {
  const trigger = useScrollTrigger();
  if (!isValidElement(children)) return null;

  if (isNative) return <>{children}</>;

  return (
    <Slide appear={false} direction="down" in={!trigger} className="no-print">
      {children}
    </Slide>
  );
};

export default HideOnScroll;
