import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from 'queries';
import { Duration } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { fetchTenantConfig } from 'services/tenant/TenantGate/fetchTenantConfig';
import { EnvTenant } from 'types/env.types';

export const useTenantConfigInterval = (
  config: UseCommonQueryOptions<EnvTenant, ReturnType<typeof QueryKeys.tenants.interval>> = {},
) => {
  const { data, isLoading, isSuccess, isError, error } = useQuery({
    queryKey: QueryKeys.tenants.interval(),
    queryFn: () => fetchTenantConfig(),
    staleTime: Duration.FIVE_MIN,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: true,
    ...config,
  });

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    error,
  };
};
