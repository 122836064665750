import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import sessionStorage from 'redux-persist/es/storage/session';

import config from 'config';

type PushNotificationsState = {
  endpointArn: string;
};

export const pushNotificationsPersistConfig = {
  key: `${config.LOCAL_STORAGE_ID}.pushNotifications`,
  storage: sessionStorage,
};

const initialState: PushNotificationsState = {
  endpointArn: '',
};

const pushNotificationsSlice = createSlice({
  name: 'pushNotifications',
  initialState,
  reducers: {
    setEndpointArn: (state, { payload }: PayloadAction<string>) => {
      state.endpointArn = payload;
    },
  },
});

export const { actions: pushNotificationsActions } = pushNotificationsSlice;

export default pushNotificationsSlice.reducer;
