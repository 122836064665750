import { FeedToken, MeasurementsToken } from 'types/token.types';

import RestApiService from '.';

export default {
  getFeedToken(this: RestApiService) {
    return this.api.get<FeedToken>(`/api/cards/token`);
  },
  getMeasurementsToken(this: RestApiService) {
    return this.api.get<MeasurementsToken>('/api/measurements/token');
  },
};
