import { TabContext, TabList } from '@mui/lab';
import { Box, Tab, Typography } from '@mui/material';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useIsModuleEnabled } from 'utils/module.utils';

import { Routes } from 'constants/routes.constants';
import { useMedia } from 'hooks/useMedia';
import { useTenantConfig } from 'queries';

import { Header } from 'components/@navbar';
import { MobileHeader } from 'components/@navigations';
import { Modules } from 'components/@navigations/config';

export enum ContactsTabs {
  CONTACTS = 'contacts',
  SITES = 'sites',
}

const tabs: Array<string> = [Routes.People, Routes.Sites];

const Organisation = () => {
  const { isDesktop } = useMedia();
  const navigate = useNavigate();
  const location = useLocation();
  const isEnabledPeopleModule = useIsModuleEnabled(Modules.People);
  const isEnabledSitesModule = useIsModuleEnabled(Modules.Sites);

  const zoomFactor = useTenantConfig().data?.configuration?.zoom;

  const tab = tabs.includes(location.pathname) ? location.pathname : Routes.People;

  useEffect(() => {
    if (location.pathname !== Routes.Sites && location.pathname !== Routes.People) {
      if (isEnabledPeopleModule) {
        navigate(Routes.People, { replace: true });
      } else {
        navigate(Routes.Sites, { replace: true });
      }
    }
  }, [isEnabledPeopleModule, location.pathname, navigate]);

  const handleTabChange = (_event: React.SyntheticEvent, newTab: Routes) => {
    navigate(newTab, { replace: true });
  };

  return (
    <>
      {isDesktop ? (
        <Box display="flex" my={3}>
          <Typography variant="h4">
            <FormattedMessage id="core_organisation_title" />
          </Typography>
        </Box>
      ) : (
        <Header>
          <MobileHeader
            backHref={Routes.Menu}
            title={<FormattedMessage id="core_organisation_title" />}
          />
        </Header>
      )}

      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleTabChange}
            variant="scrollable"
            TabIndicatorProps={{
              sx: { display: zoomFactor && Number(zoomFactor) !== 1 ? 'none' : 'block' },
            }}
          >
            {isEnabledPeopleModule && (
              <Tab
                label={<FormattedMessage id="core_menu_item_contacts" />}
                value={Routes.People}
              />
            )}
            {isEnabledSitesModule && (
              <Tab label={<FormattedMessage id="core_menu_item_sites" />} value={Routes.Sites} />
            )}
          </TabList>
        </Box>

        <Outlet />
      </TabContext>
    </>
  );
};

export default Organisation;
