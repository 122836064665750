import axios from 'axios';

import { getRatio } from 'utils/file.utils';

import { StorageMedia } from 'types/media.types';

import RestApiService from '.';

export default {
  async uploadMedia(this: RestApiService, file: File) {
    const aspectRatio = await getRatio(file);
    const { data: storageFile } = await this.api.post<StorageMedia>(`/api/storage/media`, {
      name: file.name,
      mime_type: file.type,
      aspect_ratio: aspectRatio,
    });

    await postSignedFileBinary(storageFile.signedUrl, file);
    await this.api.put<StorageMedia>(`/api/storage/media/${storageFile.id}`, { is_uploaded: true });

    return storageFile;
  },
  getMediaById(this: RestApiService, mediaId: string) {
    return this.api.get<StorageMedia>(`/api/storage/media/${mediaId}`);
  },
};

const postSignedFileBinary = (signedUrl: string, file: File) => {
  // Don't use axiosInstance since this request is external and the Authorization header causes a problem.
  return axios.put(signedUrl, file, {
    headers: {
      'Content-Type': file.type,
      'x-ms-blob-type': 'BlockBlob',
      Authorization: undefined,
    },
  });
};
