import { Box, styled } from '@mui/material';

type CardTagContainerProps = {
  radius?: number;
  bgcolor: string;
  isMobile?: boolean;
};

export const CardTagContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'radius' && prop !== 'bgcolor' && prop !== 'isMobile',
})<CardTagContainerProps>(({ radius, bgcolor, isMobile = false, theme }) => {
  return {
    position: 'relative',
    backgroundColor: bgcolor,
    borderRadius: `${radius}px ${radius}px 0 0`,
    zIndex: theme.mixins.zIndex.cardTag,
    top: 0,
    //We do this so it still fits for the gooey text
    maxWidth: isMobile ? '80%' : '100%',
    width: 'fit-content',
    minWidth: 'min-content',
    transition: theme.transitions.create(['background-color']),
    ':after': {
      position: 'absolute',
      content: '""',
      width: 16,
      height: 16,
      bottom: 0,
      right: -15,
      backgroundColor: bgcolor,
      transition: theme.transitions.create(['background-color']),
      mask: 'radial-gradient(circle at 16px 0px, transparent 16px, #000 0px)',
      zIndex: 0,
    },
  };
});
