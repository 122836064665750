import { createTheme } from '@mui/material/styles';

import { breakpoints } from './breakpoints';
import { mixins } from './mixins';
import * as components from './overrides';
import { palette } from './palette';
import { typography } from './typography';

/**
 *  Theme
 */
const theme = createTheme({
  palette,
  components,
  typography,
  mixins,
  breakpoints,
});

export default theme;
