import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type CookieBannerState = {
  showBanner: boolean;
};

const initialState: CookieBannerState = {
  showBanner: true,
};

const cookieBannerSlice = createSlice({
  name: 'cookieBanner',
  initialState,
  reducers: {
    toggleCookieBanner: (state, { payload }: PayloadAction<boolean>) => {
      state.showBanner = payload;
    },
  },
});

export const { actions: cookieBannerActions } = cookieBannerSlice;

export default cookieBannerSlice.reducer;
