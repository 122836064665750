import config from 'config';

import RestApiService from './api';
import { createManager } from './api/axios';

export let apiService: RestApiService;
export let manager: ReturnType<typeof createManager>;

// services are initialised after the tenant config is loaded, so all correct tenant config values are used
export const initServices = () => {
  manager = createManager({ baseURL: config.API_BASE });
  apiService = new RestApiService({
    api: manager,
  });
};
