export enum Routes {
  NotFound = '*',

  // General
  Profile = '/profile/:serviceId',
  Settings = '/settings',
  Chat = '/chat',
  Menu = '/menu',

  // Search
  Search = '/search',

  // News
  News = '/news',
  NewsDetail = '/news/:itemId',
  NewsDetailPreview = '/news/:itemId/preview',
  NewsDetailComments = '/news/:itemId/#comments',

  // Forms
  Forms = '/forms',
  FormsDetail = '/forms/:itemId',

  // Organisation
  Organisation = '/organisation',
  People = '/organisation/people',
  Sites = '/organisation/sites',

  // Sites
  SitesDetail = '/sites/:siteId',

  // Links
  Links = '/links',
  IframeDetail = '/iframes/:id',

  // Bookmarks
  Bookmarks = '/bookmarks',

  // Surveys
  SurveyDetail = '/surveys/:surveyId',
  SurveyEdit = '/surveys/:surveyId/edit',

  // Documents
  Documents = '/documents',
  DocumentsFolder = '/documents/folder/:folderId',
  DocumentsDetail = '/documents/item/:documentId',
  DocumentsFavourites = '/documents/favourites',

  // Tasks
  Tasks = '/tasks',
  // generic routes
  RedirectNotAuthorized = '/login',
}
