import { useEffect } from 'react';

import { useHrefs, useInfiniteLinks } from 'queries';
import { Link, LinkType } from 'types/links.types';

export const useHighlightedLinks = (type: LinkType) => {
  const {
    data: links,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteLinks({ type: type, featured: true });
  const { data: highlightedLinksData } = useHrefs<Link>(
    links?.map((link) => (link ? link.href : '')) ?? [],
  );

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  return {
    highlightedLinks: highlightedLinksData,
  };
};
