import { useQuery } from '@tanstack/react-query';

import { Duration } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { WorkeloMagicLink } from 'types/api.types';

const fetchWorkeloMagicLink = async () => {
  const { data: response } = await apiService.getWorkeloMagicLink();
  return response;
};

export const useWorkeloMagicLink = (
  config: UseCommonQueryOptions<
    WorkeloMagicLink,
    ReturnType<typeof QueryKeys.workelo.magicLink>
  > = {},
) => {
  const { isSuccess, data, isLoading, error } = useQuery({
    queryKey: QueryKeys.workelo.magicLink(),
    queryFn: fetchWorkeloMagicLink,
    staleTime: Duration.FIVE_MIN,
    ...config,
  });

  return {
    data,
    isLoading,
    isSuccess,
    error,
  };
};
