import { Avatar, Stack, StackProps, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { Routes } from 'constants/routes.constants';
import { useMedia } from 'hooks/useMedia';
import { useCurrentUser, useMe, usePicture, useTenantConfig } from 'queries';
import useChatModuleEnabled from 'services/chat/hooks/useChatModuleEnabled';
import { UserRoles } from 'types/configuration.types';

import Icon from 'components/@icons';
import LinkItem from 'components/@navigations/DrawerNavigation/LinkItem';
import NavItem from 'components/@navigations/DrawerNavigation/NavItem';

import { UnreadMessageCounter } from './components/UnreadMessageCounter';

const BottomNav = ({ ...rest }: StackProps) => {
  const intl = useIntl();
  const { isMobile, isDesktop } = useMedia();
  const { user, serviceId } = useCurrentUser();
  const { data: profilePicture } = usePicture(
    {
      picture: user?.profilePicture,
      params: { resolution: 'small' },
    },
    { meta: { store: true } },
  );

  const { data: me } = useMe();

  // Get all roles from the UserRoles enum
  const allUserRoles = Object.values(UserRoles);
  // Check if the user has any role defined in the UserRoles enum
  const showBackofficeLink = isDesktop && me?.roles.some((role) => allUserRoles.includes(role));

  const appId = useTenantConfig().data?.configuration?.company?.id;
  const backofficeUrl = appId ? `https://${appId}-backoffice.spencer.co` : 'https://spencer.co';

  const { isChatEnabled } = useChatModuleEnabled();

  return (
    <Stack spacing={1} {...rest}>
      {isChatEnabled && (
        <NavItem
          to={Routes.Chat}
          label={intl.formatMessage({ id: 'module_chat_general_name', defaultMessage: 'Chat' })}
          startIcon={<Icon name="IcoMail" />}
          removeBorderRadius={isMobile}
          action={(isActive) => <UnreadMessageCounter isActive={isActive} />}
        />
      )}

      {showBackofficeLink ? (
        <LinkItem
          to={backofficeUrl}
          label={intl.formatMessage({
            id: 'core_profile_item_backoffice',
            defaultMessage: 'Admin',
          })}
          startIcon={<Icon name="IcoOffice" />}
          removeBorderRadius={isMobile}
          target="_blank"
        />
      ) : null}

      <NavItem
        to={Routes.Settings}
        label={intl.formatMessage({ id: 'core_profile_item_settings' })}
        startIcon={<Icon name="IcoSettings" />}
        removeBorderRadius={isMobile}
      />

      <NavItem
        to={generatePath(Routes.Profile, { serviceId })}
        label={intl.formatMessage({ id: 'core_menu_item_profile' })}
        startIcon={
          <Avatar
            sx={(theme) => ({
              width: 24,
              height: 24,
              backgroundColor: theme.palette.neutral01[600],
            })}
            src={profilePicture ?? ''}
          >
            <Typography variant="caption">
              {user?.firstname.charAt(0)}
              {user?.lastname?.charAt(0)}
            </Typography>
          </Avatar>
        }
        removeBorderRadius={isMobile}
      />
    </Stack>
  );
};

export default BottomNav;
