export enum OnboardingSteps {
  selectLanguage = 'selectLanguage',
  privacy = 'privacy',
  selectTopics = 'selectTopics',
  selectNotifications = 'selectNotifications',
}

export type OnboardingProps = {
  skipLanguageStep: boolean;
  skipPrivacyStep: boolean;
  setActiveStep: (step: OnboardingSteps) => void;
};
