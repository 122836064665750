export enum FeatureFlags {
  NEWS_COMMENTS = 'news_comments',
  NEWS_FEEDBACK = 'news_feedback',
  NEWS_PRINT = 'news_print',
  NOTIFICATIONS_DIGESTMAIL = 'notifications_digestmail',
  ONBOARDING_PRIVACYPOLICY = 'onboarding_privacypolicy',
  PROFILE_FLAGINFO = 'profile_flaginfo',
  PROFILE_PICTURE = 'profile_picture',
  SEARCH_PEOPLE = 'search_people',
  SEARCH_SITES = 'search_sites',
  CHAT_WEB = 'chat_web',
  CHAT_MOBILE = 'chat_mobile',
  DOCUMENTS_MODULE = 'module_documentlibrary',
  RANDOM_FEATURED_NEWS = 'random_featured_news',
}
