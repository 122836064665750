import {
  init,
  reactRouterV6BrowserTracingIntegration,
  wrapCreateBrowserRouter,
} from '@sentry/react';
import { useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import config from 'config';

export const initWebSentry = () => {
  init({
    ...config.SENTRY,
    tracesSampleRate: 0.05,
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    ignoreErrors: ['window.crypto.randomUUID is not a function'],
    release: config.VERSION,
  });
};

export const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);
