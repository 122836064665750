import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from 'types/user.types';

interface UserState {
  userId: User['spencerUuid'];
}

const initialState: UserState = {
  userId: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state, { payload }: PayloadAction<UserState['userId']>) => {
      state.userId = payload;
    },
  },
});

export const { actions: userActions } = userSlice;

export default userSlice.reducer;
