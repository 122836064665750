import { Collection } from 'types/api.types';

export const defaultGetNextPageParam = (lastPage: Collection<Array<unknown>>) => {
  const next = lastPage?.links?.next;
  if (!next) return undefined;
  const nextPage = new URL(next).searchParams.get('p');
  return nextPage ?? undefined;
};

export const defaultInitialPageParam = '1';
