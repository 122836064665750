import config from 'config';

import { useCurrentUser } from 'queries';
import { RouteType } from 'types/api.types';

const getApiRouteByType = (type: RouteType, id: string, userId: string) => {
  switch (type) {
    case RouteType.User:
      return `/users/${id}`;
    case RouteType.NewsItem:
      return `/news/items/${id}`;
    case RouteType.NewsItemPreview:
      return `/news/items/${id}/preview`;
    case RouteType.UserBookmark:
      return `/users/${userId}/news/bookmarks/${id}`;
    case RouteType.Topic:
      return `/news/topics/${id}`;
    case RouteType.DocumentLibrary:
      return `/document-library/node/${id}`;
    case RouteType.Survey:
      return `/forms/surveys/${id}`;
    case RouteType.Site:
      return `/sites/${id}`;
    case RouteType.UserServiceId:
      return `/users/service-uuid/${id}`;
    default:
      return '';
  }
};

export const useHrefById = () => {
  const { userId } = useCurrentUser();

  const hrefById = (type: RouteType, id?: string) => {
    if (!id) return '';

    const apiSlug = getApiRouteByType(type, id, userId);

    if (!apiSlug) return '';

    return `${config.API_BASE}/api${apiSlug}`;
  };

  return { hrefById };
};
