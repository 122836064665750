import { BottomNavigation as MUIBottomNavigation, BottomNavigationAction } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Routes } from 'constants/routes.constants';
import { useMedia } from 'hooks/useMedia';
import { useDeviceInfo } from 'services/capacitor/CapacitorProvider';
import useChatModuleEnabled from 'services/chat/hooks/useChatModuleEnabled';

import { IconMessageWithBadge } from 'components/@chat/IconMessageWithBadge';
import Icon from 'components/@icons';

const DefaultFooter = () => {
  const { isDesktop } = useMedia();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { bottom } = useDeviceInfo();

  const { isChatEnabled } = useChatModuleEnabled();

  const handelRoute = () => {
    if (pathname === Routes.News) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      navigate(Routes.News);
    }
  };

  if (isDesktop) return null;

  return (
    <MUIBottomNavigation
      showLabels
      value={/\/(\w+)/.exec(pathname)?.[0]}
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        mb: `${bottom}px`,
      }}
    >
      {/*Can't be a link component since we have to scroll to top if on current page */}
      <BottomNavigationAction
        icon={<Icon name="IcoNews" />}
        label={<FormattedMessage id="module_companynews_menu_item_overview" />}
        onClick={handelRoute}
        value={Routes.News}
      />
      <BottomNavigationAction
        component={Link}
        icon={<Icon name="IcoLinks" />}
        label={<FormattedMessage id="core_launchpad_section_menu" />}
        to={Routes.Menu}
        value={Routes.Menu}
      />
      {isChatEnabled && (
        <BottomNavigationAction
          component={Link}
          icon={<IconMessageWithBadge />}
          label={<FormattedMessage id="module_chat_general_name" defaultMessage="Chat" />}
          to={Routes.Chat}
          value={Routes.Chat}
        />
      )}
    </MUIBottomNavigation>
  );
};

export default DefaultFooter;
