import { Components, Theme } from '@mui/material';

export const MuiFormControlLabel: Components<Theme>['MuiFormControlLabel'] = {
  defaultProps: {
    componentsProps: {
      typography: {
        variant: 'body2',
      },
    },
  },
};
