import { Box, IconButton } from '@mui/material';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

import config from 'config';

import { Routes } from 'constants/routes.constants';
import { useMedia } from 'hooks/useMedia';

import { SearchBar, TenantLogo } from 'components/@common';
import Icon from 'components/@icons';
import { HeaderContainer } from 'components/@navbar';
import { HideOnScroll } from 'components/@navigations';

const DefaultHeader = () => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const isNewsPath = pathname === Routes.Chat;
  const { isDesktop } = useMedia();
  const logo = config.ASSETS.logo;
  if (isNewsPath) return null;
  return (
    <HideOnScroll>
      <HeaderContainer
        sx={(theme) => ({
          pl: { lg: theme.mixins.sideBar.width, xs: logo ? 0 : 2 },
        })}
      >
        <div>
          {!isDesktop && (
            <Box pl={2}>
              <TenantLogo variant="dark" />
            </Box>
          )}
        </div>
        {isDesktop ? (
          <SearchBar />
        ) : (
          <Box display="flex" gap={2} className="no-print">
            <IconButton
              size="small"
              component={Link}
              to={Routes.Search}
              aria-label={intl.formatMessage({ id: 'core_general_navigate_search' })}
            >
              <Icon name="IcoSearch" />
            </IconButton>
          </Box>
        )}
      </HeaderContainer>
    </HideOnScroll>
  );
};

export default DefaultHeader;
