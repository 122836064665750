import { Button } from '@mui/material';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRouteError } from 'react-router-dom';

import { captureException } from 'utils/sentry.utils';

import { EmptyState } from 'components/@common';

const RouteBoundary = () => {
  const error = useRouteError();

  const handleRefresh = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (!!error) {
      captureException(error);
    }
  }, [error]);

  return (
    <EmptyState
      icon="IcoWarning"
      title={<FormattedMessage id="core_general_request_review_submit_failed_title" />}
      description={<FormattedMessage id="core_general_retry_refresh" />}
      action={
        <Button onClick={handleRefresh} variant="main" size="large">
          <FormattedMessage id="core_general_refresh" />
        </Button>
      }
    />
  );
};

export default RouteBoundary;
