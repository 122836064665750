import { LazyMotion as FramerLazyMotion } from 'framer-motion';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const LazyMotion = ({ children }: Props) => {
  return (
    <FramerLazyMotion features={() => import('./features').then((resolve) => resolve.domMax)}>
      {children}
    </FramerLazyMotion>
  );
};

export default LazyMotion;
