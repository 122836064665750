import { TypographyOptions } from '@mui/material/styles/createTypography';

import { breakpointValues } from './breakpoints';

export const typography: TypographyOptions = {
  fontSize: 18, // mobile font-size is set in MuiCssBaseline (18px)
  fontFamily: 'Hanken Grotesk, sans-serif',
  h1: {
    fontFamily: 'Darker Grotesque',
    fontWeight: 900,
    fontSize: '2.671rem',
    lineHeight: '53.41px',
    [breakpointValues.down('sm')]: {
      fontSize: '1.419rem',
      lineHeight: '70%',
    },
  },
  h2: {
    fontFamily: 'Darker Grotesque',
    fontWeight: 900,
    fontSize: '2.136rem',
    lineHeight: '50.421px',
    [breakpointValues.down('sm')]: {
      fontSize: '1.261rem',
      lineHeight: '27.706px',
    },
  },
  h3: {
    fontFamily: 'Darker Grotesque',
    fontWeight: 900,
    fontSize: '1.709rem',
    lineHeight: '39.305px',
    [breakpointValues.down('sm')]: {
      fontSize: '1.3rem',
      lineHeight: '1.7rem',
    },
  },
  h4: {
    fontFamily: 'Darker Grotesque',
    fontWeight: 900,
    fontSize: '1.367rem',
    lineHeight: '30.625px',
    [breakpointValues.down('sm')]: {
      fontSize: '0.997rem',
      lineHeight: '19.733px',
    },
  },
  h5: {
    fontFamily: 'Darker Grotesque',
    fontWeight: 900,
    fontSize: '1.094rem',
    lineHeight: '25.158px',
    [breakpointValues.down('sm')]: {
      fontSize: '0.886rem',
      lineHeight: '19.453px',
    },
  },
  h6: {
    fontFamily: 'Darker Grotesque',
    fontWeight: 900,
    fontSize: '0.875rem',
    lineHeight: '22.75px',
    [breakpointValues.down('sm')]: {
      fontSize: '0.788rem',
      lineHeight: '17.01px',
    },
  },
  bodyLead: {
    fontSize: '1.250rem',
    lineHeight: '32.5px',
    [breakpointValues.down('sm')]: {
      fontSize: '1.125rem',
      lineHeight: '32.4px',
    },
  },
  body1: {
    fontSize: '1rem',
    lineHeight: '32px',
    [breakpointValues.down('sm')]: {
      fontSize: '1rem',
      lineHeight: '31.5px',
    },
  },
  body2: {
    fontSize: '0.800rem',
    lineHeight: '24px',
    [breakpointValues.down('sm')]: {
      fontSize: '0.889rem',
      lineHeight: '24px',
    },
  },
  overline: {
    fontWeight: 500,
    fontSize: '0.640rem',
    lineHeight: '16px',
    [breakpointValues.down('sm')]: {
      fontSize: '0.79rem',
      lineHeight: '16.35px',
    },
  },
  caption: {
    fontWeight: 500,
    fontSize: '0.512rem',
    fontStyle: 'normal',
    lineHeight: '12.29px',
    [breakpointValues.down('sm')]: {
      fontSize: '0.702rem',
      lineHeight: '15.8px',
    },
  },
};
