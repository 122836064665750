import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ExternalLinkFeedbackModalState = {
  showModal: boolean;
};

const initialState: ExternalLinkFeedbackModalState = {
  showModal: false,
};

const externalLinkFeedbackModalSlice = createSlice({
  name: 'externalLinkFeedbackModal',
  initialState,
  reducers: {
    toggleFeedbackModal: (state, { payload }: PayloadAction<boolean>) => {
      state.showModal = payload;
    },
  },
});

export const { actions: externalLinkFeedbackModalActions } = externalLinkFeedbackModalSlice;

export default externalLinkFeedbackModalSlice.reducer;
