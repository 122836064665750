import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { IconNames } from 'sprite/types';

import { Routes } from 'constants/routes.constants';
import { useHighlightedLinks } from 'hooks/useHighlightedLinks';
import { useEnabledModules, useInfiniteLinks, useMe, useTenantConfig } from 'queries';
import { useWorkeloMagicLink } from 'queries/workelo';
import { Href } from 'types/api.types';
import { FeatureFlags } from 'types/features.types';
import { LinkType } from 'types/links.types';

import { iframeModules, Modules, webModules } from './config';

type NavItem =
  | { Component: React.FunctionComponent }
  | {
      label: string;
      to: string;
      fetched: boolean;
      target?: string;
      startIcon?: IconNames;
      startImage?: Href;
      showInMobileMenu?: boolean;
    };

export const useEnabledIframeModules = () => {
  const { data: enabledModules, isLoading: isLoadingEnabledModules } = useEnabledModules();
  const { data: meData, isLoading: isLoadingMe } = useMe();

  const enabledIframeModules = useMemo(
    () =>
      iframeModules.filter(
        (module) =>
          enabledModules?.includes(module.id) && !meData?.restrictedModules?.includes(module.id),
      ),
    [enabledModules, meData],
  );

  return { enabledIframeModules, isLoading: isLoadingEnabledModules || isLoadingMe };
};

const useEnabledWebModules = () => {
  const { data: enabledModules } = useEnabledModules();
  const { data: meData } = useMe();
  const { data: tenantConfig } = useTenantConfig();

  const enabledWebModules = useMemo(
    () =>
      webModules.filter((module) =>
        module.ids.some((id) => {
          // Step 1: Check if module is enabled
          const isEnabled = enabledModules?.includes(id);
          // Step 2: Check if module is not restricted for the user
          const isNotRestricted = !meData?.restrictedModules?.includes(id);
          // Step 3: Check if the corresponding feature is enabled in tenantConfig
          const featureKey = Object.keys(tenantConfig?.configuration?.features || {}).find(
            (key) => key.toLowerCase() === id.toLowerCase(),
          ) as FeatureFlags | undefined;
          const isFeatureEnabled = featureKey
            ? (tenantConfig?.configuration?.features as Record<FeatureFlags, boolean>)?.[
                featureKey
              ] !== false
            : true;

          return isEnabled && isNotRestricted && isFeatureEnabled;
        }),
      ),
    [enabledModules, meData, tenantConfig],
  );

  return enabledWebModules;
};

// INFO: Hook is only for the Workelo module 'module_workelo' a link should be shown in the menu called Workelo
const useWorkelo = () => {
  const { data: enabledModules, isLoading: isLoadingEnabledModules } = useEnabledModules();

  const enabledWorkelo = useMemo(() => enabledModules?.includes(Modules.Workelo), [enabledModules]);

  const { data: workeloMagicLink, isLoading: isLoadingWorkeloMagicLink } = useWorkeloMagicLink({
    enabled: enabledWorkelo, // Run the query only if the Workelo module is enabled
  });

  const workeloNavItem = workeloMagicLink?.magicLink
    ? {
        label: 'module_workelo_overview_title',
        to: workeloMagicLink.magicLink,
        target: '_blank',
        startIcon: 'IcoWorkelo' as IconNames,
        fetched: false,
      }
    : null;

  return { workeloNavItem, isLoading: isLoadingEnabledModules || isLoadingWorkeloMagicLink };
};

export const useNavItems = () => {
  const { enabledIframeModules } = useEnabledIframeModules();
  const enabledWebModules = useEnabledWebModules();
  const { highlightedLinks } = useHighlightedLinks(LinkType.WebLink);
  const { data: links, isLoading: isLoadingLinks } = useInfiniteLinks({
    featured: false,
    type: LinkType.WebLink,
  });
  const { data: forms, isLoading: isLoadingForms } = useInfiniteLinks({
    featured: false,
    type: LinkType.Form,
  });
  const { highlightedLinks: highlightedForms } = useHighlightedLinks(LinkType.Form);
  const { workeloNavItem, isLoading: isLoadingWorkelo } = useWorkelo();

  const emptyLinks = !links?.length && !isLoadingLinks;
  const emptyForms = !forms?.length && !isLoadingForms;
  const emptyWorkelo = !workeloNavItem?.to && !isLoadingWorkelo;

  const navItems = useMemo<Array<NavItem>>(
    () => [
      // core modules
      ...enabledWebModules.filter((webModule) => {
        const linkModule = webModule.ids.find((id) => id === Modules.LinkLibrary);
        const formsModule = webModule.ids.find((id) => id === Modules.Forms);
        if (emptyLinks && linkModule) return;
        if (emptyForms && formsModule) return;
        return webModule;
      }),
      // enabled iframe modules
      ...enabledIframeModules.map((iframeModule) => ({
        label: iframeModule.name,
        to: iframeModule.path,
        startIcon: iframeModule.iconName,
        fetched: false,
      })),

      // Workelo
      ...(workeloNavItem && !emptyWorkelo ? [workeloNavItem] : []),

      ...highlightedLinks.map((link) => ({
        label: link.name,
        to: link.openExternal
          ? link.webUrl
          : generatePath(Routes.IframeDetail, { id: String(link.id) }),
        target: link.openExternal ? '_blank' : undefined,
        startImage: link.image,
        fetched: true,
      })),
      // @TODO Do highlighted forms still exist? I can't find a way to make these in the backoffice anymore.
      // If they do exist/are added again later, we might have to set this as the value of `to`: generatePath(Routes.FormsDetail, { itemId: String(link.id) })
      ...highlightedForms.map((link) => ({
        label: link.name,
        to: generatePath(Routes.FormsDetail, { itemId: String(link.id) }),
        startImage: link.image,
        fetched: true,
      })),
    ],
    [
      enabledWebModules,
      enabledIframeModules,
      highlightedLinks,
      workeloNavItem,
      highlightedForms,
      emptyLinks,
      emptyForms,
      emptyWorkelo,
    ],
  );

  return navItems;
};
